import { useParams } from "@tanstack/react-router";
import { Button, Input, Loader, Row, Text } from "@gradience/ui";
import PageChrome from "../../components/page-chrome";
import { useApiPut, useApiQuery } from "../../lib/api";
import { ReactNode, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import stripTimeZone from "../../lib/strip-time-zone";

const formatDateForInput = (_date: Date) => {
  const date = stripTimeZone(_date);
  const day = ("0" + date.getDate()).slice(-2);
  const month = ("0" + (date.getMonth() + 1)).slice(-2);

  return date.getFullYear() + "-" + month + "-" + day;
};

const dateOrUndefined = (date: string | undefined) =>
  date ? new Date(date) : undefined;

const TestDetail = (): ReactNode => {
  const params = useParams({ from: "/tests/$slug" });

  const queryClient = useQueryClient();
  const updateTest = useApiPut(
    "/tests/:slug",
    {
      slug: params.slug,
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["tests"]);
      },
    }
  );
  const test = useApiQuery(
    "/tests/:slug",
    {
      slug: params.slug,
    },
    {
      enabled: !updateTest.isLoading,
    }
  );

  const loading = updateTest.isLoading;

  const [_reservationDateStart, setReservationDateStart] = useState<Date>();
  const reservationDateStart =
    _reservationDateStart ?? dateOrUndefined(test.data?.reservationDateStart);
  const [_reservationDateEnd, setReservationDateEnd] = useState<Date>();
  const reservationDateEnd =
    _reservationDateEnd ?? dateOrUndefined(test.data?.reservationDateEnd);
  const [_configurationDateStart, setConfigurationDateStart] = useState<Date>();
  const configurationDateStart =
    _configurationDateStart ??
    dateOrUndefined(test.data?.configurationDateStart);
  const [_configurationDateEnd, setConfigurationDateEnd] = useState<Date>();
  const configurationDateEnd =
    _configurationDateEnd ?? dateOrUndefined(test.data?.configurationDateEnd);
  const [_administrationDateStart, setAdministrationDateStart] =
    useState<Date>();
  const administrationDateStart =
    _administrationDateStart ??
    dateOrUndefined(test.data?.administrationDateStart);
  const [_administrationDateEnd, setAdministrationDateEnd] = useState<Date>();
  const administrationDateEnd =
    _administrationDateEnd ?? dateOrUndefined(test.data?.administrationDateEnd);
  const [_completionDate, setCompletionDate] = useState<Date>();
  const completionDate =
    _completionDate ?? dateOrUndefined(test.data?.completionDate);

  return (
    <PageChrome>
      {test.isInitialLoading ? (
        <Loader />
      ) : (
        <>
          <Row gap={8}>
            <Text
              textStyle="headingLarge"
              style={{
                flex: 1,
              }}
            >
              {test.data?.name}
            </Text>
            <Button
              type="button"
              text="Cancel"
              linkProps={{
                to: "/questions/$poolId",
                params: {
                  poolId: params.poolId,
                },
              }}
              disabled={loading}
            />
            <Button
              type="button"
              variant="primary"
              text="Save"
              onPress={() => {
                administrationDateStart &&
                  administrationDateEnd &&
                  updateTest.mutate({
                    body: {
                      reservationDateStart: reservationDateStart?.toISOString(),
                      reservationDateEnd: reservationDateEnd?.toISOString(),
                      configurationDateStart:
                        configurationDateStart?.toISOString(),
                      configurationDateEnd: configurationDateEnd?.toISOString(),
                      administrationDateStart:
                        administrationDateStart.toISOString(),
                      administrationDateEnd:
                        administrationDateEnd.toISOString(),
                      completionDate: completionDate?.toISOString(),
                    },
                  });
              }}
              loading={loading}
            />
          </Row>
          <Text>
            See{" "}
            <a href="https://help.gradience.co/en/category/basics/article/creating-and-administering-tests">
              here
            </a>{" "}
            for more information about these dates
          </Text>
          <DateInput
            label="Reservation Date Start"
            value={reservationDateStart}
            setValue={setReservationDateStart}
          />
          <DateInput
            label="Reservation Date End"
            value={reservationDateEnd}
            setValue={setReservationDateEnd}
          />
          <DateInput
            label="Configuration Date Start"
            value={configurationDateStart}
            setValue={setConfigurationDateStart}
          />
          <DateInput
            label="Configuration Date End"
            value={configurationDateEnd}
            setValue={setConfigurationDateEnd}
          />
          <DateInput
            label="Administration Date Start"
            value={administrationDateStart}
            setValue={setAdministrationDateStart}
          />
          <DateInput
            label="Administration Date End"
            value={administrationDateEnd}
            setValue={setAdministrationDateEnd}
          />
          <DateInput
            label="Completion Date"
            value={completionDate}
            setValue={setCompletionDate}
          />
        </>
      )}
    </PageChrome>
  );
};

export default TestDetail;

const DateInput = ({
  label,
  value,
  setValue,
}: {
  label: string;
  value: Date | undefined;
  setValue: (value: Date | undefined) => void;
}) => (
  <Input
    label={label}
    type="date"
    value={value ? formatDateForInput(value) : ""}
    setValue={(value) => {
      if (value === "") {
        setValue(undefined);
      } else setValue(new Date(value));
    }}
  />
);
