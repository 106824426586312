import {
  Text,
  Loader,
  Checkbox,
  useDesignTokens,
  Row,
  ClickableText,
} from "@gradience/ui";
import PageChrome from "../../components/page-chrome";
import { useApiQuery } from "../../lib/api";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  SortingState,
  getSortedRowModel,
} from "@tanstack/react-table";
import { useMemo, useState } from "react";
import { Test } from "@gradience/api-types";
import { testRoute } from "../..";
import { Link } from "@tanstack/react-router";

const columnHelper = createColumnHelper<Test>();

function Tests() {
  const tests = useApiQuery("/tests", {}, {});

  const columns = useMemo(
    () => [
      columnHelper.display({
        id: "selected",
        header: ({ table }) => (
          <Checkbox
            value={table.getIsAllRowsSelected()}
            onChange={(value) => table.toggleAllRowsSelected(value)}
          />
        ),
        cell: ({ row }) => (
          <Checkbox
            value={row.getIsSelected()}
            onChange={(value) => row.toggleSelected(value)}
          />
        ),
      }),
      columnHelper.accessor("slug", {
        header: "Slug",
        cell: (row) => (
          <Text>
            <Link
              style={{
                textDecoration: "none",
                color: designTokens.colors.brand[100],
              }}
              params={{
                slug: row.getValue(),
              }}
              to={testRoute.fullPath}
              search={{
                step: -1,
              }}
            >
              {row.getValue()}
            </Link>
          </Text>
        ),
      }),
    ],
    []
  );

  const [sorting, setSorting] = useState<SortingState>([]);
  const { getHeaderGroups, getRowModel } = useReactTable({
    columns,
    state: {
      sorting,
    },
    data: tests.data?.data ?? [],
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
  });

  const designTokens = useDesignTokens();
  const TestsTable = () => (
    <table
      style={{
        width: "100%",
        borderCollapse: "collapse",
      }}
    >
      <thead>
        {getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th
                key={header.id}
                style={{
                  paddingTop: 14,
                  paddingBottom: 14,
                  paddingLeft: 24,
                  paddingRight: 24,
                  borderTop: `1px solid ${designTokens.colors.border.subdued}`,
                  borderBottom: `1px solid ${designTokens.colors.border.subdued}`,
                  textAlign: "start",
                  cursor: header.column.getCanSort() ? "pointer" : undefined,
                }}
                onClick={header.column.getToggleSortingHandler()}
              >
                <Text textStyle="strong">
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                  {{
                    asc: <span> ↑</span>,
                    desc: <span> ↓</span>,
                  }[header.column.getIsSorted() as string] ?? null}
                </Text>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {getRowModel().rows?.map((row) => (
          <tr key={row.id}>
            {row.getVisibleCells()?.map((cell) => (
              <td
                key={cell.id}
                style={{
                  paddingTop: 14,
                  paddingBottom: 14,
                  paddingLeft: 24,
                  paddingRight: 24,
                  borderTop: `1px solid ${designTokens.colors.border.subdued}`,
                }}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );

  return (
    <PageChrome>
      <Row
        style={{
          paddingTop: 11,
          paddingBottom: 11,
          gap: 8,
        }}
      >
        <Text
          textStyle="headingLarge"
          style={{
            flexGrow: 1,
          }}
        >
          Tests
        </Text>
      </Row>
      {tests.isLoading ? <Loader /> : <TestsTable />}
    </PageChrome>
  );
}

export default Tests;
