import {
  Text,
  useDesignTokens,
  Row,
  Column,
  Loader,
  Button,
  Card,
  Input,
} from "@gradience/ui";
import Konva from "konva";
import { Stage, Layer, Transformer, Rect } from "react-konva";
import PageChrome from "../../components/page-chrome";
import { useApiPost, useApiQuery } from "../../lib/api";
import { Fragment, useEffect, useRef, useState } from "react";
import { useHover } from "@uidotdev/usehooks";
import { AnswerCircleAnnotation } from "@gradience/api-types/src/answer-circle-annotation";
import { useNavigate, useSearch } from "@tanstack/react-router";
import { manualGradingErrorsRoute } from "../..";

function ManualGradingErrors() {
  const testGradingProgress = useApiQuery("/grading-progress", {
    refetchOnWindowFocus: false,
  });

  const numberOfFailedPages = testGradingProgress.data?.data.reduce(
    (acc, step) => {
      if (step.name === "identify-fiducial-marks") {
        return acc + step.progress.failed;
      } else if (step.name === "read-qr-codes") {
        return acc + step.progress.failed;
      }
      return acc;
    },
    0
  );

  const { gradingPage } = useSearch({ from: manualGradingErrorsRoute.id });
  const navigate = useNavigate();
  const setGradingPage = (page: number) => {
    navigate({
      to: manualGradingErrorsRoute.id,
      search: {
        gradingPage: page,
      },
    });
  };

  const failedScan = useApiQuery(
    "/test-scan-pages",
    {},
    { refetchOnWindowFocus: false },
    {
      failedIdentifyingFiducialsOrQrCode: "true",
      take: "1",
      skip: gradingPage.toString(),
      distinctByPageNumberAndStudent: "false",
    }
  );

  const school = useApiQuery(
    "/school",
    {},
    {
      enabled: Boolean(failedScan.data?.data[0]?.schoolId),
    },
    { id: failedScan.data?.data[0]?.schoolId ?? "" }
  );

  const designTokens = useDesignTokens();

  const [answerCirclePositions, setAnswerCirclePositions] = useState<
    {
      x: number;
      y: number;
      width: number;
      height: number;
      filledIndex: number | null;
    }[]
  >([]);

  const [imageNaturalWidth, setImageNaturalWidth] = useState<number | null>(
    null
  );
  const [imageWidth, setImageWidth] = useState<number | null>(null);
  const [imageHeight, setImageHeight] = useState<number | null>(null);
  const imageRef = useRef<HTMLImageElement>(null);

  const AnswerCircle = ({
    x,
    y,
    scale,
    index,
    filled,
    setFilled,
  }: {
    x: number;
    y: number;
    scale: number;
    index: number;
    filled: boolean;
    setFilled: (filled: boolean) => void;
  }) => {
    const [ref, hovering] = useHover();

    return (
      <span
        ref={ref}
        onClick={() => setFilled(!filled)}
        style={{
          position: "absolute",
          left: x,
          top: y + scale * index * 1.3,
          width: scale,
          height: scale,
          borderRadius: "50%",
          border: "2px solid red",
          boxSizing: "border-box",
          backgroundColor: filled ? "red" : hovering ? "pink" : "transparent",
        }}
      />
    );
  };

  const [student, setStudent] = useState("");
  const students = useApiQuery(
    "/students",
    {},
    { enabled: Boolean(school.data?.id), refetchOnWindowFocus: false },
    { schoolId: school.data?.id ?? "" }
  );

  const [page, setPage] = useState<number>();

  const answerCircleAnnotationMutation = useApiPost(
    "/test-scan-pages/:id/answer-circle-annotations",
    {
      onSuccess: () => {
        setGradingPage(gradingPage + 1);
        setAnswerCirclePositions([]);
        setStudent("");
        setPage(undefined);
      },
    },
    { id: failedScan.data?.data[0]?.id ?? "" }
  );

  return (
    <PageChrome>
      {testGradingProgress.data ? (
        <Row>
          <Column
            style={{
              flexGrow: 1,
              alignItems: "center",
            }}
          >
            <div
              style={{
                position: "relative",
                width: "100%",
                height: "100%",
                maxWidth: 700,
              }}
            >
              <img
                ref={imageRef}
                src={failedScan.data?.data[0]?.imageUrl}
                style={{ width: "100%", height: "100%" }}
                alt="Failed scan"
                onLoad={(event) => {
                  setImageNaturalWidth(event.currentTarget.naturalWidth);
                  setImageWidth(event.currentTarget.width);
                  setImageHeight(event.currentTarget.height);
                }}
              />
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                }}
              >
                <Stage
                  width={imageWidth ?? 0}
                  height={imageHeight ?? 0}
                  onClick={(event) => {
                    if (event.target !== event.currentTarget) {
                      console.log(event.target, event.currentTarget);
                      return;
                    }

                    const clickX = event.evt.offsetX;
                    const clickY = event.evt.offsetY;
                    const circleSize =
                      answerCirclePositions[answerCirclePositions.length - 1]
                        ?.width ?? 20;

                    setAnswerCirclePositions((prev) => [
                      ...prev,
                      {
                        x: clickX - circleSize / 2,
                        y: clickY - circleSize / 2,
                        width: circleSize,
                        height: circleSize * 4.8,
                        filledIndex: null,
                      },
                    ]);
                  }}
                >
                  {answerCirclePositions.map(
                    ({ x, y, width, height }, index) => {
                      console.log({ x: x, y, width, height });
                      // const imageScale =
                      //   (imageRef.current?.clientWidth ?? 0) /
                      //   (imageNaturalWidth ?? 0);
                      // const width = 20 * scale;

                      // const x = pos.x * imageScale - width / 2;
                      // const y = pos.y * imageScale - width / 2;

                      // const distanceBetweenCircles = 26 * scale;

                      return (
                        <ResizableBox
                          key={index}
                          boxPosition={{
                            x,
                            y,
                            width,
                            height,
                            rotation: 0,
                          }}
                          setBoxPosition={(position) => {
                            setAnswerCirclePositions(
                              (answerCirclePositions) => {
                                const updatedAnswerCirclePositions = [
                                  ...answerCirclePositions,
                                ];
                                updatedAnswerCirclePositions[index] = {
                                  x: position.x,
                                  y: position.y,
                                  width: position.width,
                                  height: position.height,
                                  filledIndex:
                                    updatedAnswerCirclePositions[index]
                                      .filledIndex,
                                };
                                return updatedAnswerCirclePositions;
                              }
                            );
                          }}
                        />
                      );
                    }
                  )}
                </Stage>
                {answerCirclePositions.map(
                  ({ x, y, width, filledIndex }, index) => {
                    return (
                      <Fragment key={index}>
                        <AnswerCircle
                          x={x}
                          y={y}
                          scale={width}
                          index={0}
                          filled={filledIndex === 0}
                          setFilled={(filled) => {
                            setAnswerCirclePositions((prev) => {
                              const updatedAnswerCirclePositions = [...prev];
                              updatedAnswerCirclePositions[index] = {
                                ...updatedAnswerCirclePositions[index],
                                filledIndex: filled ? 0 : null,
                              };
                              return updatedAnswerCirclePositions;
                            });
                          }}
                        />
                        <AnswerCircle
                          x={x}
                          y={y}
                          scale={width}
                          index={1}
                          filled={filledIndex === 1}
                          setFilled={(filled) => {
                            setAnswerCirclePositions((prev) => {
                              const updatedAnswerCirclePositions = [...prev];
                              updatedAnswerCirclePositions[index] = {
                                ...updatedAnswerCirclePositions[index],
                                filledIndex: filled ? 1 : null,
                              };
                              return updatedAnswerCirclePositions;
                            });
                          }}
                        />
                        <AnswerCircle
                          x={x}
                          y={y}
                          scale={width}
                          index={2}
                          filled={filledIndex === 2}
                          setFilled={(filled) => {
                            setAnswerCirclePositions((prev) => {
                              const updatedAnswerCirclePositions = [...prev];
                              updatedAnswerCirclePositions[index] = {
                                ...updatedAnswerCirclePositions[index],
                                filledIndex: filled ? 2 : null,
                              };
                              return updatedAnswerCirclePositions;
                            });
                          }}
                        />
                        <AnswerCircle
                          x={x}
                          y={y}
                          scale={width}
                          index={3}
                          filled={filledIndex === 3}
                          setFilled={(filled) => {
                            setAnswerCirclePositions((prev) => {
                              const updatedAnswerCirclePositions = [...prev];
                              updatedAnswerCirclePositions[index] = {
                                ...updatedAnswerCirclePositions[index],
                                filledIndex: filled ? 3 : null,
                              };
                              return updatedAnswerCirclePositions;
                            });
                          }}
                        />
                      </Fragment>
                    );
                  }
                )}
              </div>
            </div>
          </Column>
          <Column
            style={{
              flexBasis: 300,
              flexGrow: 0,
              gap: 16,
            }}
          >
            <Card
              style={{
                padding: 24,
                gap: 4,
              }}
            >
              <Text textStyle="headingXS">{school.data?.name}</Text>
              <Text style={{ color: designTokens.colors.text.disabled }}>
                {school.data?.address.city}, {school.data?.address.state}
              </Text>
            </Card>
            <Card
              style={{
                padding: 24,
                gap: 4,
              }}
            >
              <Input
                label="Student name"
                options={
                  students.data?.data.map((student) => ({
                    value: student.id,
                    label: [student.firstName, student.lastName].join(" "),
                  })) ?? []
                }
                setValue={(value) => setStudent(value)}
                value={student}
                type="select"
              />
              <Input
                label="Page number"
                type="select"
                options={Array.from({ length: 10 }, (_, i) => i).map(
                  (pageNumber) => ({
                    value: (pageNumber + 1).toString(),
                    label: (pageNumber + 1).toString(),
                  })
                )}
                setValue={(value) => setPage(Number(value))}
                value={page === undefined ? "" : page.toString()}
              />
            </Card>
            <Card
              style={{
                padding: 24,
                gap: 4,
              }}
            >
              <Text textStyle="headingSmall" style={{ flexGrow: 1 }}>
                Grading {gradingPage + 1} / {numberOfFailedPages}
              </Text>
              <Row
                gap={8}
                style={{
                  alignItems: "center",
                  padding: 16,
                }}
              >
                <Button
                  onPress={() => setGradingPage(gradingPage - 1)}
                  disabled={gradingPage === 0}
                  text="Previous"
                />
                <Button
                  onPress={() => {
                    // each position represents four answers. Normalize these to the size of the page
                    const annotations: AnswerCircleAnnotation[] = [];

                    for (const position of answerCirclePositions) {
                      const scale = imageWidth! / imageNaturalWidth!;

                      for (const index of [0, 1, 2, 3]) {
                        annotations.push({
                          x: position.x / scale,
                          y:
                            position.y / scale +
                            (index * 1.3 * position.width) / scale,
                          width: position.width / scale,
                          // The position is as tall as the four circles combined
                          height: position.width / scale,
                          filled: position.filledIndex === index,
                        });
                      }
                    }

                    answerCircleAnnotationMutation.mutate({
                      body: {
                        annotations,
                        studentId: student,
                        pageNumber: page!,
                      },
                    });
                  }}
                  disabled={!student || page === undefined}
                  loading={answerCircleAnnotationMutation.isLoading}
                  text={
                    gradingPage === (numberOfFailedPages ?? 0) - 1
                      ? "Finish"
                      : "Next"
                  }
                  variant="primary"
                />
              </Row>
            </Card>
          </Column>
        </Row>
      ) : (
        <Loader />
      )}
    </PageChrome>
  );
}

const ResizableBox = ({
  boxPosition,
  setBoxPosition,
}: {
  boxPosition: {
    x: number;
    y: number;
    width: number;
    height: number;
    rotation: number;
  };
  setBoxPosition: (position: {
    x: number;
    y: number;
    width: number;
    height: number;
    rotation: number;
  }) => void;
}) => {
  const shapeRef = useRef<Konva.Rect>(null);
  const transformerRef = useRef<Konva.Transformer>(null);
  const layerRef = useRef<Konva.Layer>(null);

  useEffect(() => {
    if (transformerRef.current && shapeRef.current) {
      transformerRef.current.nodes([shapeRef.current]);
      transformerRef.current.getLayer()?.batchDraw();
    }
  }, []);

  return (
    <>
      <Layer ref={layerRef}>
        <Rect
          onTransformEnd={(event) => {
            setBoxPosition({
              x: event.target.x(),
              y: event.target.y(),
              width: event.target.scaleX(),
              height: event.target.scaleY(),
              rotation: event.target.rotation(),
            });
          }}
          onDragEnd={(event) => {
            setBoxPosition({
              x: event.target.x(),
              y: event.target.y(),
              width: event.target.scaleX(),
              height: event.target.scaleY(),
              rotation: event.target.rotation(),
            });
          }}
          x={boxPosition.x}
          y={boxPosition.y}
          width={1}
          height={1}
          scaleX={boxPosition.width}
          scaleY={boxPosition.height}
          draggable
          ref={shapeRef}
        />
        <Transformer
          ref={transformerRef}
          ignoreStroke
          keepRatio
          enabledAnchors={[
            "top-left",
            "top-right",
            "bottom-left",
            "bottom-right",
          ]}
        />
      </Layer>
    </>
  );
};

export default ManualGradingErrors;
