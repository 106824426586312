import {
  Text,
  Loader,
  useDesignTokens,
  Row,
  MarkdownRenderer,
  Button,
  Tooltip,
} from "@gradience/ui";
import PageChrome from "../../components/page-chrome";
import { useApiDelete, useApiPost, useApiQuery } from "../../lib/api";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Chapter } from "@gradience/api-types";
import { useMemo } from "react";
import { Link, useNavigate, useParams } from "@tanstack/react-router";
import { useQueryClient } from "@tanstack/react-query";
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
} from "@dnd-kit/sortable";

const columnHelper = createColumnHelper<Chapter>();

function CurriculumPage() {
  const queryClient = useQueryClient();
  const { id: curriculumId } = useParams({
    from: "/curricula/$id",
  });
  const curricula = useApiQuery(`/curricula`, {});
  const curiculum = curricula.data?.data.find(
    (curriculum) => curriculum.id === curriculumId
  );
  const chapters = useApiQuery(
    "/chapters",
    {},
    {
      enabled: curricula.isSuccess,
    },
    {
      curriculumIds: [curriculumId],
    }
  );

  const chapterOrderMutation = useApiPost("/chapters/order", {});

  const reorderChapters = (sourceIndex: number, targetIndex: number) => {
    // Log all query keys
    const queryKey = [
      "chapters",
      {},
      {
        curriculumIds: [curriculumId],
      },
    ];
    const ogChapters = queryClient.getQueryData<{ data: Chapter[] }>(queryKey);
    if (ogChapters) {
      const chapters = {
        ...ogChapters,
        data: ogChapters.data.map((chapter) => ({
          ...chapter,
          conceptIds: [...chapter.conceptIds],
        })),
      };
      const [removed] = chapters.data.splice(sourceIndex, 1);
      chapters.data.splice(targetIndex, 0, removed);
      queryClient.setQueryData(queryKey, chapters);

      chapterOrderMutation.mutate({
        body: {
          chapterIds: chapters.data.map((chapter) => chapter.id),
          curriculumId,
        },
      });
    }
  };

  const concepts = useApiQuery(
    "/concepts",
    {},
    {
      enabled: chapters.isSuccess,
    }
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        header: "Name",
        cell: (row) => (
          <Text>
            <Link
              to="/curricula/$curriculumId/$chapterId"
              params={{
                curriculumId,
                chapterId: row.row.original.id,
              }}
              style={{
                color: "inherit",
                textDecoration: "none",
              }}
            >
              <MarkdownRenderer content={row.getValue()} />
            </Link>
          </Text>
        ),
      }),
      columnHelper.display({
        id: "concepts",
        header: "Concepts",
        cell: (row) => (
          <Text>
            {row.row.original.conceptIds
              .map((conceptId) => {
                const concept = concepts.data?.data.find(
                  (concept) => concept.id === conceptId
                );
                return concept?.slug ?? concept?.name;
              })
              .join(", ")}
          </Text>
        ),
      }),
    ],
    [concepts.data?.data, curriculumId]
  );

  const { getHeaderGroups, getRowModel, reset } = useReactTable({
    columns,
    data: chapters.data?.data ?? [],
    getCoreRowModel: getCoreRowModel(),
  });

  const createChapterMutation = useApiPost("/chapters", {
    onSuccess: async () => {
      await curricula.refetch();
      await chapters.refetch();
      reset();
    },
  });
  const navigate = useNavigate();

  const deleteCurriculumMutation = useApiDelete(
    "/curricula/:id",
    {
      id: curriculumId,
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["curricula"]);
        navigate({
          to: "/curricula",
        });
      },
    }
  );

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const designTokens = useDesignTokens();
  const ChaptersTable = () => (
    <table
      style={{
        width: "100%",
        borderCollapse: "collapse",
      }}
    >
      <thead>
        {getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            <th
              style={{
                paddingTop: 14,
                paddingBottom: 14,
                paddingLeft: 24,
                paddingRight: 24,
                borderTop: `1px solid ${designTokens.colors.border.subdued}`,
                borderBottom: `1px solid ${designTokens.colors.border.subdued}`,
                textAlign: "start",
              }}
            />
            {headerGroup.headers.map((header) => (
              <th
                key={header.id}
                style={{
                  paddingTop: 14,
                  paddingBottom: 14,
                  paddingLeft: 24,
                  paddingRight: 24,
                  borderTop: `1px solid ${designTokens.colors.border.subdued}`,
                  borderBottom: `1px solid ${designTokens.colors.border.subdued}`,
                  textAlign: "start",
                }}
              >
                <Text textStyle="strong">
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </Text>
              </th>
            ))}
          </tr>
        ))}
      </thead>

      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={({ active, over }) => {
          if (over && active.id !== over.id) {
            const sourceIndex = getRowModel().rows.findIndex(
              (row) => row.original.id === active.id
            );
            const targetIndex = getRowModel().rows.findIndex(
              (row) => row.original.id === over.id
            );
            reorderChapters(sourceIndex, targetIndex);
          }
        }}
      >
        <SortableContext
          items={getRowModel().rows?.map((row) => row.original.id) ?? []}
        >
          <tbody>
            {getRowModel().rows?.map((row) => (
              <ChapterDraggable id={row.original.id}>
                {row.getVisibleCells()?.map((cell) => (
                  <td
                    key={cell.id}
                    style={{
                      paddingTop: 14,
                      paddingBottom: 14,
                      paddingLeft: 24,
                      paddingRight: 24,
                      borderTop: `1px solid ${designTokens.colors.border.subdued}`,
                    }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </ChapterDraggable>
            ))}
          </tbody>
        </SortableContext>
      </DndContext>
    </table>
  );
  const hasChapters = (chapters.data?.data.length ?? 1) > 0;

  return (
    <PageChrome>
      <Row
        style={{
          paddingTop: 11,
          paddingBottom: 11,
          gap: 8,
        }}
      >
        <Text
          textStyle="headingLarge"
          style={{
            flexGrow: 1,
          }}
        >
          {curiculum?.name} Chapters
        </Text>
        <Button
          type="button"
          text="Back"
          linkProps={{
            to: "/curricula",
          }}
          disabled={
            createChapterMutation.isLoading ||
            deleteCurriculumMutation.isLoading ||
            chapterOrderMutation.isLoading
          }
        />
      </Row>
      {chapters.isInitialLoading ? (
        <Loader />
      ) : (
        <>
          <span
            style={{
              flex: 1,
            }}
          >
            <ChaptersTable />
          </span>
          <Button
            style={{
              alignSelf: "flex-start",
            }}
            text="Add chapter"
            onPress={() => {
              createChapterMutation.mutate({
                body: {
                  name: "New chapter",
                  curriculumId,
                },
              });
            }}
          />
          <Tooltip
            content={
              hasChapters
                ? "Delete each chapter before deleting the curriculum"
                : undefined
            }
            style={{
              alignSelf: "flex-start",
            }}
          >
            <Button
              style={{
                alignSelf: "flex-start",
              }}
              disabled={hasChapters}
              text="Delete curriculum"
              onPress={() => {
                if (window.confirm("Are you sure?")) {
                  deleteCurriculumMutation.mutate({
                    id: curriculumId,
                  });
                }
              }}
            />
          </Tooltip>
        </>
      )}
    </PageChrome>
  );
}

const ChapterDraggable = ({
  children,
  id,
}: {
  children: React.ReactNode;
  id: string;
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id,
    });
  const style = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
        transition,
      }
    : undefined;

  const designTokens = useDesignTokens();

  return (
    <tr style={style} ref={setNodeRef}>
      <td
        style={{
          paddingTop: 14,
          paddingBottom: 14,
          paddingLeft: 24,
          paddingRight: 24,
          borderTop: `1px solid ${designTokens.colors.border.subdued}`,
        }}
        {...listeners}
        {...attributes}
      >
        <Text
          style={{
            cursor: "grab",
          }}
        >
          <span
            style={{
              paddingRight: 8,
            }}
          >
            ☰
          </span>
        </Text>
      </td>
      {children}
    </tr>
  );
};

export default CurriculumPage;
