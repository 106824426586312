import { useNavigate, useParams } from "@tanstack/react-router";
import { Button, Input, Loader, Row, Text } from "@gradience/ui";
import PageChrome from "../../components/page-chrome";
import { useApiDelete, useApiPut, useApiQuery } from "../../lib/api";
import { ReactNode, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import stripTimeZone from "../../lib/strip-time-zone";

const formatDateForInput = (_date: Date) => {
  const date = stripTimeZone(_date);
  const day = ("0" + date.getDate()).slice(-2);
  const month = ("0" + (date.getMonth() + 1)).slice(-2);

  return date.getFullYear() + "-" + month + "-" + day;
};

const dateOrUndefined = (date: string | undefined) =>
  date ? new Date(date) : undefined;

const TestDatesOverrideDetail = (): ReactNode => {
  const params = useParams({ from: "/test-dates-overrides/$id" });

  const queryClient = useQueryClient();
  const updateOverride = useApiPut(
    "/test-dates-overrides/:id",
    {
      id: params.id,
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["test-dates-overrides"]);
      },
    }
  );
  const testDatesOverrides = useApiQuery(
    "/test-dates-overrides",
    {},
    {
      enabled: !updateOverride.isLoading,
    }
  );
  const override = testDatesOverrides.data?.data.find(
    (override) => override.id === params.id
  );

  const schools = useApiQuery(
    "/school-list",
    {},
    {
      enabled: !!override,
    }
  );
  const school = schools.data?.find(
    (school) => school.id === override?.schoolId
  );

  const deleteTestDatesOverride = useApiDelete(
    "/test-dates-overrides/:id",
    {
      id: params.id,
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["test-dates-overrides"]);
      },
    }
  );

  const loading = updateOverride.isLoading;

  const [_reservationDateStart, setReservationDateStart] = useState<Date>();
  const reservationDateStart =
    _reservationDateStart ?? dateOrUndefined(override?.reservationDateStart);
  const [_reservationDateEnd, setReservationDateEnd] = useState<Date>();
  const reservationDateEnd =
    _reservationDateEnd ?? dateOrUndefined(override?.reservationDateEnd);
  const [_configurationDateStart, setConfigurationDateStart] = useState<Date>();
  const configurationDateStart =
    _configurationDateStart ??
    dateOrUndefined(override?.configurationDateStart);
  const [_configurationDateEnd, setConfigurationDateEnd] = useState<Date>();
  const configurationDateEnd =
    _configurationDateEnd ?? dateOrUndefined(override?.configurationDateEnd);
  const [_administrationDateStart, setAdministrationDateStart] =
    useState<Date>();
  const administrationDateStart =
    _administrationDateStart ??
    dateOrUndefined(override?.administrationDateStart);
  const [_administrationDateEnd, setAdministrationDateEnd] = useState<Date>();
  const administrationDateEnd =
    _administrationDateEnd ?? dateOrUndefined(override?.administrationDateEnd);
  const [_completionDate, setCompletionDate] = useState<Date>();
  const completionDate =
    _completionDate ?? dateOrUndefined(override?.completionDate);

  const navigate = useNavigate();

  return (
    <PageChrome>
      {testDatesOverrides.isInitialLoading ? (
        <Loader />
      ) : (
        <>
          <Row gap={8}>
            <Text
              textStyle="headingLarge"
              style={{
                flex: 1,
              }}
            >
              Test dates override for {school?.name}
            </Text>
            <Button
              type="button"
              text="Cancel"
              linkProps={{
                to: "/questions/$poolId",
                params: {
                  poolId: params.poolId,
                },
              }}
              disabled={loading}
            />
            <Button
              type="button"
              variant="primary"
              text="Save"
              onPress={() => {
                administrationDateStart &&
                  administrationDateEnd &&
                  updateOverride.mutate({
                    body: {
                      reservationDateStart: reservationDateStart!.toISOString(),
                      reservationDateEnd: reservationDateEnd!.toISOString(),
                      configurationDateStart:
                        configurationDateStart!.toISOString(),
                      configurationDateEnd: configurationDateEnd!.toISOString(),
                      administrationDateStart:
                        administrationDateStart.toISOString(),
                      administrationDateEnd:
                        administrationDateEnd.toISOString(),
                      completionDate: completionDate!.toISOString(),
                      testId: override!.testId,
                      schoolId: override!.schoolId,
                    },
                  });
              }}
              loading={loading}
            />
          </Row>
          <Text>
            See{" "}
            <a href="https://help.gradience.co/en/category/basics/article/creating-and-administering-tests">
              here
            </a>{" "}
            for more information about these dates
          </Text>
          <DateInput
            label="Reservation Date Start"
            value={reservationDateStart}
            setValue={setReservationDateStart}
          />
          <DateInput
            label="Reservation Date End"
            value={reservationDateEnd}
            setValue={setReservationDateEnd}
          />
          <DateInput
            label="Configuration Date Start"
            value={configurationDateStart}
            setValue={setConfigurationDateStart}
          />
          <DateInput
            label="Configuration Date End"
            value={configurationDateEnd}
            setValue={setConfigurationDateEnd}
          />
          <DateInput
            label="Administration Date Start"
            value={administrationDateStart}
            setValue={setAdministrationDateStart}
          />
          <DateInput
            label="Administration Date End"
            value={administrationDateEnd}
            setValue={setAdministrationDateEnd}
          />
          <DateInput
            label="Completion Date"
            value={completionDate}
            setValue={setCompletionDate}
          />
        </>
      )}
      <Button
        type="button"
        variant="primary"
        text="Delete override"
        onPress={async () => {
          if (
            window.confirm(
              "Are you sure you want to delete this override? Test dates will revert to the default dates for this test"
            )
          ) {
            await deleteTestDatesOverride.mutateAsync({});
            navigate({ to: "/schools" });
          }
        }}
        style={{
          alignSelf: "flex-end",
        }}
        disabled={loading}
      />
    </PageChrome>
  );
};

export default TestDatesOverrideDetail;

const DateInput = ({
  label,
  value,
  setValue,
}: {
  label: string;
  value: Date | undefined;
  setValue: (value: Date | undefined) => void;
}) => (
  <Input
    label={label}
    type="date"
    value={value ? formatDateForInput(value) : ""}
    setValue={(value) => {
      if (value === "") {
        setValue(undefined);
      } else setValue(new Date(value));
    }}
  />
);
