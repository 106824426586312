import { useDesignTokens } from "@gradience/ui";

const AuthChrome = ({ children }: { children: React.ReactNode }) => {
  const designTokens = useDesignTokens();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        backgroundColor: designTokens.colors.surface.subdued,
      }}
    >
      {children}
    </div>
  );
};

export default AuthChrome;
