import { useParams } from "@tanstack/react-router";
import {
  Button,
  Column,
  Input,
  Loader,
  Row,
  Text,
  useDesignTokens,
} from "@gradience/ui";
import PageChrome from "../../components/page-chrome";
import { useApiPut, useApiQuery } from "../../lib/api";
import { ReactNode, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import MarkdownInput from "../../components/markdown-input";

const ConceptDetail = (): ReactNode => {
  const params = useParams({ from: "/concepts/$id" });

  const concepts = useApiQuery("/concepts", {}, {});
  const concept = concepts.data?.data.find(
    (concept) => concept.id === params.id
  );

  const [_conceptName, setConceptName] = useState<string>();
  const conceptName = _conceptName ?? concept?.name ?? "";
  const [_conceptSlug, setConceptSlug] = useState<string>();
  const conceptSlug = _conceptSlug ?? concept?.slug ?? "";
  const [_conceptShortDescription, setConceptShortDescription] =
    useState<string>();
  const conceptShortDescription =
    _conceptShortDescription ?? concept?.shortDescription ?? "";
  const [_conceptStudyGuideText, setConceptStudyGuideText] = useState<string>();
  const conceptStudyGuideText =
    _conceptStudyGuideText ?? concept?.studyGuideText ?? "";

  const queryClient = useQueryClient();
  const updateConcept = useApiPut(
    "/concepts/:id",
    {
      id: params.id,
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["concepts"]);
      },
    }
  );

  const designTokens = useDesignTokens();

  const loading = updateConcept.isLoading;

  return (
    <PageChrome>
      {concepts.isInitialLoading ? (
        <Loader />
      ) : (
        <>
          <Row gap={8}>
            <Text
              textStyle="headingLarge"
              style={{
                flex: 1,
              }}
            >
              Concept
            </Text>
            <Button
              type="button"
              text="Cancel"
              linkProps={{
                to: "/concepts",
              }}
              disabled={loading}
            />
            <Button
              type="button"
              variant="primary"
              text="Save"
              loading={loading}
              onPress={() => {
                if (!concept) {
                  return;
                }
                updateConcept.mutate({
                  body: {
                    name: conceptName,
                    slug: conceptSlug,
                    shortDescription: conceptShortDescription,
                    studyGuideText: conceptStudyGuideText,
                    groupId: concept.groupId,
                  },
                });
              }}
            />
          </Row>
          <Input label="Name" value={conceptName} setValue={setConceptName} />
          <Input label="Slug" value={conceptSlug} setValue={setConceptSlug} />
          <Input
            label="Short description"
            value={conceptShortDescription}
            setValue={setConceptShortDescription}
          />
          <Column gap={8}>
            <Text textStyle="strong">Study guide text</Text>
            <MarkdownInput
              value={conceptStudyGuideText}
              setValue={setConceptStudyGuideText}
            />
          </Column>
          <Text
            textStyle="caption"
            style={{
              color: designTokens.colors.text.disabled,
            }}
          >
            Use ⌘ + B to bold and ⌘ + I to italicize and ⌘ + U to underline
          </Text>
        </>
      )}
    </PageChrome>
  );
};

export default ConceptDetail;
