import {
  Text,
  Loader,
  useDesignTokens,
  Row,
  Button,
  Card,
  Column,
} from "@gradience/ui";
import PageChrome from "../../components/page-chrome";
import { queryKeys, useApiPost, useApiPut, useApiQuery } from "../../lib/api";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Concept, ConceptGroup } from "@gradience/api-types";
import { useMemo, useState } from "react";
import { Link } from "@tanstack/react-router";
import styled from "styled-components";
import {
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
} from "@dnd-kit/sortable";
import {
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  useDroppable,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { useQueryClient } from "@tanstack/react-query";

const columnHelper = createColumnHelper<Concept>();
const SelectableCard = styled(Card)`
  cursor: pointer;

  &:hover {
    background: ${(props) => props.theme.colors.surface.subdued} !important;
  }
`;

function Concepts() {
  const conceptGroups = useApiQuery("/concept-groups", {});

  // const createCurriculumMutation = useApiPost("/curricula", {
  //   onSuccess: () => {
  //     concepts.refetch();
  //   },
  // });

  const designTokens = useDesignTokens();
  const [selectedConceptGroupId, setSelectedConceptGroupId] = useState<
    string | undefined
  >();

  const selectedConceptGroup = conceptGroups.data?.data.find(
    (group) => group.id === selectedConceptGroupId
  );
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );
  const [draggingConceptId, setDraggingConceptId] = useState<string | null>(
    null
  );
  const draggingConcept = selectedConceptGroup?.concepts?.find(
    (concept) => concept.id === draggingConceptId
  );
  const [draggingOverGroupId, setDraggingOverGroupId] = useState<string | null>(
    null
  );
  const putConceptMutation = useApiPut(`/concepts/:id`, {
    id: draggingConceptId ?? "",
  });
  const putConceptGroupMutation = useApiPut(`/concept-groups/:id`, {
    id: selectedConceptGroupId ?? "",
  });
  const queryClient = useQueryClient();

  const createConceptGroupMutation = useApiPost(`/concept-groups`, {
    onSuccess: () => {
      conceptGroups.refetch();
    },
  });

  const [isSorting, setIsSorting] = useState(false);

  return (
    <PageChrome>
      <DndContext
        sensors={sensors}
        onDragStart={({ active }) => {
          setDraggingConceptId(active.id.toString() ?? null);
        }}
        onDragEnd={async ({ active, over, ...rest }) => {
          if (isSorting && over?.id && active.id) {
            // sort the concepts
            const oldData = queryClient.getQueryData<{ data: ConceptGroup[] }>([
              ...queryKeys["/concept-groups"],
              {},
              {},
            ]);

            if (oldData) {
              const oldIds = oldData.data
                .find((group) => group.id === selectedConceptGroupId)
                ?.concepts?.map((concept) => concept.id);

              const sourceIndex = oldIds?.findIndex(
                (id) => id === active.id.toString()
              );
              const destinationIndex = oldIds?.findIndex(
                (id) => id === over.id.toString()
              );

              if (
                sourceIndex !== undefined &&
                destinationIndex !== undefined &&
                selectedConceptGroup &&
                selectedConceptGroupId
              ) {
                const newIds = [...(oldIds ?? [])];

                const [removed] = newIds.splice(sourceIndex, 1);
                newIds.splice(destinationIndex, 0, removed);
                const newConcepts = newIds.map(
                  (id) =>
                    oldData.data
                      .find((group) => group.id === selectedConceptGroupId)
                      ?.concepts?.find((concept) => concept.id === id)!
                );

                putConceptGroupMutation.mutate({
                  body: {
                    name: selectedConceptGroup.name,
                    conceptIds: newIds,
                  },
                  params: {
                    id: selectedConceptGroupId,
                  },
                });

                queryClient.setQueryData<{ data: ConceptGroup[] }>(
                  [...queryKeys["/concept-groups"], {}, {}],
                  {
                    data: [
                      ...oldData.data.map((group) => {
                        if (group.id === selectedConceptGroupId) {
                          return {
                            ...group,
                            concepts: newConcepts,
                          };
                        } else {
                          return { ...group };
                        }
                      }),
                    ],
                  }
                );
              }
            }
          } else if (draggingOverGroupId && draggingConcept) {
            putConceptMutation.mutate({
              body: {
                name: draggingConcept.name,
                slug: draggingConcept.slug,
                shortDescription: draggingConcept.shortDescription,
                studyGuideText: draggingConcept.studyGuideText,
                groupId: draggingOverGroupId,
              },
              params: {
                id: draggingConcept.id,
              },
            });

            const oldData = queryClient.getQueryData<{ data: ConceptGroup[] }>([
              ...queryKeys["/concept-groups"],
              {},
              {},
            ]);

            if (oldData) {
              queryClient.setQueryData<{ data: ConceptGroup[] }>(
                [...queryKeys["/concept-groups"], {}, {}],
                {
                  data: [
                    ...oldData.data.map((group) => {
                      if (group.id === draggingOverGroupId) {
                        return {
                          ...group,
                          concepts: [
                            ...(group.concepts.map((concept) => ({
                              ...concept,
                            })) ?? []),
                            draggingConcept,
                          ],
                        };
                      } else if (group.id === selectedConceptGroupId) {
                        return {
                          ...group,
                          concepts:
                            group.concepts
                              ?.filter(
                                (concept) => concept.id !== draggingConcept.id
                              )
                              .map((concept) => ({
                                ...concept,
                              })) ?? [],
                        };
                      } else {
                        return { ...group };
                      }
                    }),
                  ],
                }
              );
            }
          }

          setDraggingConceptId(null);
        }}
        onDragOver={({ over }) => {
          const overId = over?.id.toString() ?? null;
          if (conceptGroups.data?.data.find((group) => group.id === overId)) {
            setDraggingOverGroupId(overId);
          } else {
            setDraggingOverGroupId(null);
          }

          if (over?.data.current?.sortable.containerId === "sort-concepts") {
            setIsSorting(true);
          } else {
            setIsSorting(false);
          }
        }}
      >
        <Row
          gap={24}
          style={{
            flex: 1,
          }}
        >
          <Column gap={24}>
            <Row
              style={{
                gap: 8,
              }}
            >
              <Text
                textStyle="headingLarge"
                style={{
                  flexGrow: 1,
                }}
              >
                Concepts
              </Text>
            </Row>
            <Text>
              Click on a concept group to view its concepts. Drag concepts onto
              another group name to move them.
            </Text>
            {conceptGroups.isInitialLoading ? (
              <Loader />
            ) : (
              <>
                <Row
                  style={{
                    flexWrap: "wrap",
                    gap: 24,
                    alignItems: "flex-start",
                  }}
                >
                  {conceptGroups.data?.data.map((group) => (
                    <ConceptGroupCard
                      group={group}
                      onSelect={() => setSelectedConceptGroupId(group.id)}
                      isSelected={group.id === selectedConceptGroupId}
                      key={group.id}
                    />
                  )) ?? null}
                </Row>
                <Button
                  onPress={() => {
                    const name = window.prompt(
                      "Concept Group Name",
                      "Unnamed Concept Group"
                    );
                    if (name) {
                      createConceptGroupMutation.mutate({
                        body: {
                          name,
                        },
                      });
                    }
                  }}
                  text="New Concept Group"
                  // disabled={createCurriculumMutation.isLoading}
                  style={{
                    alignSelf: "flex-start",
                  }}
                />
              </>
            )}
          </Column>
          <Column
            style={{
              flexBasis: 240,
              flexGrow: 0,
              flexShrink: 0,
              borderLeft: `1px solid ${designTokens.colors.border.subdued}`,
              overflowY: "scroll",
              position: "relative",
            }}
          >
            {selectedConceptGroup ? (
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
                }}
              >
                <Column
                  style={{
                    gap: 24,
                  }}
                >
                  <Row
                    style={{
                      padding: 8,
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-start",
                      gap: 8,
                      boxSizing: "border-box",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      text="Edit group"
                      linkProps={{
                        to: `/concepts/groups/$groupId`,
                        params: {
                          groupId: selectedConceptGroup.id,
                        },
                      }}
                    />
                    {(putConceptGroupMutation.isLoading ||
                      putConceptMutation.isLoading) && (
                      <Text
                        style={{
                          flexGrow: 1,
                          textAlign: "end",
                        }}
                        textStyle="caption"
                      >
                        Saving...
                      </Text>
                    )}
                  </Row>
                  <ConceptGroupConcepts
                    draggingConceptId={draggingConceptId}
                    group={selectedConceptGroup}
                  />
                </Column>
              </div>
            ) : (
              <Text
                textStyle="caption"
                style={{
                  alignSelf: "center",
                  flex: 1,
                  justifySelf: "center",
                }}
              >
                Select a concept group
              </Text>
            )}
          </Column>
        </Row>
      </DndContext>
    </PageChrome>
  );
}

const ConceptGroupConcepts = ({
  group,
  draggingConceptId,
}: {
  group: ConceptGroup;
  draggingConceptId: string | null;
}) => {
  const designTokens = useDesignTokens();

  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        header: "Name",
        cell: (row) => <Text>{row.getValue()}</Text>,
      }),
    ],
    []
  );
  const { getHeaderGroups, getRowModel } = useReactTable({
    columns,
    data: group.concepts ?? [],
    getCoreRowModel: getCoreRowModel(),
  });

  const draggingConcept = group.concepts?.find(
    (concept) => concept.id === draggingConceptId
  );

  return (
    <>
      <SortableContext
        items={getRowModel().rows?.map((row) => row.original.id) ?? []}
        id="sort-concepts"
      >
        <table
          style={{
            flex: 1,
            borderCollapse: "collapse",
          }}
        >
          <thead>
            {getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                <th
                  style={{
                    paddingTop: 14,
                    paddingBottom: 14,
                    paddingLeft: 24,
                    paddingRight: 24,
                    borderTop: `1px solid ${designTokens.colors.border.subdued}`,
                    borderBottom: `1px solid ${designTokens.colors.border.subdued}`,
                    textAlign: "start",
                  }}
                ></th>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    style={{
                      paddingTop: 14,
                      paddingBottom: 14,
                      paddingLeft: 24,
                      paddingRight: 24,
                      borderTop: `1px solid ${designTokens.colors.border.subdued}`,
                      borderBottom: `1px solid ${designTokens.colors.border.subdued}`,
                      textAlign: "start",
                    }}
                  >
                    <Text textStyle="strong">
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </Text>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {getRowModel().rows?.map((row) => (
              <ConceptDraggable key={row.id} id={row.original.id}>
                {row.getVisibleCells()?.map((cell) => (
                  <td
                    key={cell.id}
                    style={{
                      paddingTop: 14,
                      paddingBottom: 14,
                      paddingLeft: 24,
                      paddingRight: 24,
                      borderTop: `1px solid ${designTokens.colors.border.subdued}`,
                    }}
                  >
                    <Link
                      to="/concepts/$id"
                      params={{
                        id: row.original.id,
                      }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </Link>
                  </td>
                ))}
              </ConceptDraggable>
            ))}
            <DragOverlay
              wrapperElement="tr"
              style={{
                border: `2px dashed ${designTokens.colors.border.subdued}`,
                borderRadius: 12,
              }}
            >
              {draggingConcept ? (
                <>
                  <td
                    style={{
                      paddingTop: 14,
                      paddingBottom: 14,
                      paddingLeft: 24,
                      paddingRight: 24,
                    }}
                  >
                    <Text>
                      <span
                        style={{
                          paddingRight: 8,
                        }}
                      >
                        ☰
                      </span>
                    </Text>
                  </td>
                  <td
                    style={{
                      paddingTop: 14,
                      paddingBottom: 14,
                      paddingLeft: 24,
                      paddingRight: 24,
                    }}
                  >
                    <Text>{draggingConcept.name}</Text>
                  </td>
                </>
              ) : null}
            </DragOverlay>
          </tbody>
        </table>
      </SortableContext>
    </>
  );
};

const ConceptDraggable = ({
  children,
  id,
}: {
  children: React.ReactNode;
  id: string;
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id,
  });

  const style = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
        transition,
      }
    : undefined;

  const designTokens = useDesignTokens();

  return (
    <tr
      ref={setNodeRef}
      style={{ opacity: isDragging ? 0 : undefined, ...style }}
    >
      <td
        style={{
          paddingTop: 14,
          paddingBottom: 14,
          paddingLeft: 24,
          paddingRight: 24,
          borderTop: `1px solid ${designTokens.colors.border.subdued}`,
        }}
        {...listeners}
        {...attributes}
      >
        <Text
          style={{
            cursor: "grab",
          }}
        >
          <span
            style={{
              paddingRight: 8,
            }}
          >
            ☰
          </span>
        </Text>
      </td>
      {children}
    </tr>
  );
};

const ConceptGroupCard = ({
  group,
  onSelect,
  isSelected,
}: {
  group: ConceptGroup;
  onSelect: () => unknown;
  isSelected: boolean;
}) => {
  const designTokens = useDesignTokens();
  const { setNodeRef, isOver } = useDroppable({
    id: group.id,
    disabled: isSelected,
  });
  return (
    <button
      ref={setNodeRef}
      key={group.id}
      style={{
        border: "none",
        background: "none",
        appearance: "none",
        flexBasis: 240,
        flexGrow: 1,
        padding: 0,
        borderRadius: 12,
        flexShrink: 0,
      }}
      onClick={onSelect}
    >
      <SelectableCard
        style={{
          padding: 24,
          background: isSelected
            ? designTokens.colors.surface.selected
            : isOver
            ? designTokens.colors.surface.action
            : undefined,
        }}
      >
        <Text textStyle="strong">{group.name}</Text>
      </SelectableCard>
    </button>
  );
};

export default Concepts;
