import {
  Button,
  ClickableText,
  Column,
  Row,
  Text,
  useDesignTokens,
} from "@gradience/ui";
import { useLogout } from "../lib/auth";
import { useApiPost } from "../lib/api";

const PageChrome = ({
  children,
  style,
}: {
  children: React.ReactNode;
  style?: React.CSSProperties;
}) => {
  const designTokens = useDesignTokens();
  const logout = useLogout();

  const gradeTests = useApiPost("/grade-tests", {});

  return (
    <Column
      style={{
        height: "100vh",
      }}
    >
      <Row
        gap={24}
        style={{
          padding: 24,
          borderBottom: `1px solid ${designTokens.colors.border.subdued}`,
        }}
      >
        <Text to="/schools">Schools</Text>
        <Text to="/questions">Questions</Text>
        <Text to="/curricula">Curricula</Text>
        <Text to="/concepts">Concepts</Text>
        <Text to="/supplemental-concepts">Supplemental Concepts</Text>
        <Text to="/tests">Tests</Text>
        <Text to="/grading-progress">Grading Progress</Text>
        <Text to="/manual-grading">Manual Grading</Text>
        <span
          style={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            onPress={() => gradeTests.mutate({ body: {} })}
            text="Grade"
          />
          <ClickableText onClick={() => logout.mutate()}>Logout</ClickableText>
        </span>
      </Row>
      <Column
        gap={12}
        style={{
          padding: 24,
          flex: 1,
          ...style,
        }}
      >
        {children}
      </Column>
    </Column>
  );
};

export default PageChrome;
