import {
  Text,
  Loader,
  Checkbox,
  useDesignTokens,
  Row,
  Button,
  ClickableText,
  DropdownMenu,
  Modal,
  Column,
  DropdownButton,
} from "@gradience/ui";
import PageChrome from "../../components/page-chrome";
import { useApiPost, useApiQuery } from "../../lib/api";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  SortingState,
  getSortedRowModel,
} from "@tanstack/react-table";
import { School } from "@gradience/api-types";
import { useMemo, useRef, useState } from "react";
import formatCurrency from "../../lib/format-currency";
import exportToCsv from "../../lib/export-to-csv";
import config from "../../lib/config";
import { getLoggedInUser } from "../../lib/auth";
import displayDatetime from "../../lib/display-datetime";
import { Link, useNavigate } from "@tanstack/react-router";

const columnHelper = createColumnHelper<School>();

const getImpersonationUrl = ({
  impersonationToken,
  email,
  subdomain,
}: {
  impersonationToken: string;
  email: string;
  subdomain: string;
}) =>
  `${
    config.REACT_APP_NODE_ENV === "production"
      ? `https://${subdomain}.${config.REACT_APP_WEB_APP_BASE_URL}`
      : `http://${config.REACT_APP_WEB_APP_BASE_URL}`
  }/log-in?userImpersonationEmail=${encodeURIComponent(
    email
  )}&userImpersonationToken=${encodeURIComponent(impersonationToken)}`;

function Schools() {
  const schools = useApiQuery(
    "/school-list",
    {},
    {
      refetchOnWindowFocus: false,
    }
  );
  const tests = useApiQuery("/tests", {});
  const testDatesOverrides = useApiQuery("/test-dates-overrides", {});
  const [viewingStudentsForSchool, setViewingStudentsForSchool] = useState<
    string | null
  >(null);

  const navigate = useNavigate();

  const createTestDatesOverride = useApiPost("/test-dates-overrides", {
    onSuccess: (data) => {
      navigate({
        to: `/test-dates-overrides/$id`,
        params: {
          id: data.id,
        },
      });
    },
  });
  const snapshotScores = useApiPost("/score-snapshots");

  const getImpersonationToken = useApiPost("/auth/impersonation-token", {
    onSuccess: (data) => {
      const user = getLoggedInUser();
      if (!user) {
        throw new Error("User is not logged in");
      }
      window.open(
        getImpersonationUrl({
          email: data.email,
          impersonationToken: data.token,
          subdomain: user.tenantSubdomain,
        })
      );
    },
  });
  const users = useApiQuery(
    "/users",
    {
      id: schools.data
        ?.flatMap((school) => [
          school.headLatinTeacherId,
          school.examAdministratorId,
        ])
        .filter(Boolean),
    },
    {
      enabled: schools.isSuccess,
    }
  );

  const columns = useMemo(
    () => [
      columnHelper.display({
        id: "selected",
        header: ({ table }) => (
          <Checkbox
            value={table.getIsAllRowsSelected()}
            onChange={(value) => table.toggleAllRowsSelected(value)}
          />
        ),
        cell: ({ row }) => (
          <Checkbox
            value={row.getIsSelected()}
            onChange={(value) => row.toggleSelected(value)}
          />
        ),
      }),
      columnHelper.accessor("name", {
        header: "Name",
        cell: (row) => (
          <Text>
            <ClickableText
              onClick={() => {
                getImpersonationToken.mutate({
                  body: { schoolId: row.row.original.id },
                });
              }}
            >
              {row.getValue()}
            </ClickableText>
          </Text>
        ),
      }),
      columnHelper.display({
        header: "Scored Students",
        id: "scoredStudents",
        cell: (row) => (
          <ClickableText
            onClick={() => setViewingStudentsForSchool(row.row.original.id)}
          >
            {row.row.original.studentsWithScores} /{" "}
            {row.row.original.numberOfStudents}
          </ClickableText>
        ),
      }),
      columnHelper.accessor("orderPlacedAt", {
        header: "Order Placed At",
        cell: (row) => <Text>{displayDatetime(row.getValue())}</Text>,
      }),
      columnHelper.display({
        id: "total",
        header: "Estimated Order Total",
        cell: (row) => (
          <Text>
            {formatCurrency(
              row.row.original.orderPlacedAt
                ? (row.row.original.numberOfStudents ?? 0) * 450 + 3500
                : 0
            )}
          </Text>
        ),
      }),
      columnHelper.display({
        id: "paymentMethod",
        header: "Payment Method",
        cell: (row) => {
          const paymentMethod = row.row.original.paymentMethod;

          if (paymentMethod?.last4) {
            return <Text>Card ending in {paymentMethod.last4}</Text>;
          } else if (paymentMethod) {
            return <Text>Stripe Credit</Text>;
          } else {
            return <Text>None</Text>;
          }
        },
      }),
      columnHelper.display({
        id: "address",
        header: "Address",
        cell: (row) => {
          const address = row.row.original.address;
          return (
            <Text>
              {address?.line1}
              <br />
              {address?.line2 ? (
                <>
                  {address?.line2}
                  <br />
                </>
              ) : null}
              {address?.city} {address?.state} {address?.zip}
            </Text>
          );
        },
      }),
      columnHelper.display({
        id: "headLatinTeacher",
        header: "Head Latin Teacher",
        cell: (row) => {
          const user = users.data?.data.find(
            (user) => user.id === row.row.original.headLatinTeacherId
          );
          return <Text>{user?.email}</Text>;
        },
      }),
      columnHelper.display({
        id: "examAdministrator",
        header: "Exam Administrator",
        cell: (row) => {
          const user = users.data?.data.find(
            (user) => user.id === row.row.original.examAdministratorId
          );
          return <Text>{user?.email}</Text>;
        },
      }),
      columnHelper.display({
        id: "dateOverrides",
        header: "Date Overrides",
        cell: (row) => {
          if (testDatesOverrides.isInitialLoading) {
            return <Text>Loading...</Text>;
          }

          const override = testDatesOverrides.data?.data.find(
            (override) => override.schoolId === row.row.original.id
          );

          if (override) {
            return (
              <Link
                to={`/test-dates-overrides/$id`}
                params={{
                  id: override.id,
                }}
                style={{
                  textDecoration: "none",
                  color: "inherit",
                }}
              >
                View
              </Link>
            );
          } else {
            return (
              <ClickableText
                onClick={async () => {
                  const currentTest = tests.data?.data[0];
                  if (!currentTest) {
                    return;
                  }

                  createTestDatesOverride.mutate({
                    body: {
                      schoolId: row.row.original.id,
                      testId: currentTest.id,
                      reservationDateStart: currentTest?.reservationDateStart,
                      reservationDateEnd: currentTest?.reservationDateEnd,
                      configurationDateStart:
                        currentTest?.configurationDateStart,
                      configurationDateEnd: currentTest?.configurationDateEnd,
                      administrationDateStart:
                        currentTest?.administrationDateStart,
                      administrationDateEnd: currentTest?.administrationDateEnd,
                      completionDate: currentTest?.completionDate,
                    },
                  });
                }}
              >
                Create
              </ClickableText>
            );
          }
        },
      }),
    ],
    [
      createTestDatesOverride,
      getImpersonationToken,
      testDatesOverrides.data?.data,
      testDatesOverrides.isInitialLoading,
      tests.data?.data,
      users.data?.data,
    ]
  );

  const [showSchoolsWithOrders, setSchoolsShowWithOrders] = useState(true);
  const [showSchoolsWithoutOrders, setSchoolsShowWithoutOrders] =
    useState(true);
  const [showSchoolsWithoutStudents, setSchoolsShowWithoutStudents] =
    useState(true);

  const schoolData = useMemo(() => {
    return (
      schools.data?.filter((school) => {
        if (!showSchoolsWithoutStudents && school.numberOfStudents === 0) {
          return false;
        }

        if (showSchoolsWithOrders && school.orderPlacedAt) {
          return true;
        } else if (showSchoolsWithoutOrders && !school.orderPlacedAt) {
          return true;
        } else {
          return false;
        }
      }) ?? []
    );
  }, [
    schools.data,
    showSchoolsWithOrders,
    showSchoolsWithoutOrders,
    showSchoolsWithoutStudents,
  ]);

  const [sorting, setSorting] = useState<SortingState>([]);
  const { getHeaderGroups, getRowModel } = useReactTable({
    columns,
    state: {
      sorting,
    },
    data: schoolData,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
  });

  const designTokens = useDesignTokens();
  const SchoolsTable = () => (
    <table
      style={{
        width: "100%",
        borderCollapse: "collapse",
      }}
    >
      <thead>
        {getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th
                key={header.id}
                style={{
                  paddingTop: 14,
                  paddingBottom: 14,
                  paddingLeft: 24,
                  paddingRight: 24,
                  borderTop: `1px solid ${designTokens.colors.border.subdued}`,
                  borderBottom: `1px solid ${designTokens.colors.border.subdued}`,
                  textAlign: "start",
                  cursor: header.column.getCanSort() ? "pointer" : undefined,
                }}
                onClick={header.column.getToggleSortingHandler()}
              >
                <Text textStyle="strong">
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                  {{
                    asc: <span> ↑</span>,
                    desc: <span> ↓</span>,
                  }[header.column.getIsSorted() as string] ?? null}
                </Text>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {getRowModel().rows?.map((row) => (
          <tr key={row.id}>
            {row.getVisibleCells()?.map((cell) => (
              <td
                key={cell.id}
                style={{
                  paddingTop: 14,
                  paddingBottom: 14,
                  paddingLeft: 24,
                  paddingRight: 24,
                  borderTop: `1px solid ${designTokens.colors.border.subdued}`,
                }}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLSpanElement>(null);

  return (
    <PageChrome>
      <Row
        style={{
          paddingTop: 11,
          paddingBottom: 11,
          gap: 8,
        }}
      >
        <Text
          textStyle="headingLarge"
          style={{
            flexGrow: 1,
          }}
        >
          Schools
        </Text>
        <span ref={dropdownRef}>
          <Button
            leadingIcon="filter"
            text="Filter"
            onPress={(event) => setDropdownOpen(!dropdownOpen)}
          />
          {dropdownOpen ? (
            <DropdownMenu
              referenceElement={dropdownRef.current}
              options={[
                {
                  label: "Order Placed",
                  value: "with-orders",
                  checked: showSchoolsWithOrders,
                },
                {
                  label: "Order Not Placed",
                  value: "without-orders",
                  checked: showSchoolsWithoutOrders,
                },
                {
                  label: "No Students",
                  value: "without-students",
                  checked: showSchoolsWithoutStudents,
                },
              ]}
              setClosed={() => setDropdownOpen(false)}
              onSelect={(value) => {
                if (value === "with-orders") {
                  setSchoolsShowWithOrders(!showSchoolsWithOrders);
                } else if (value === "without-orders") {
                  setSchoolsShowWithoutOrders(!showSchoolsWithoutOrders);
                } else if (value === "without-students") {
                  setSchoolsShowWithoutStudents(!showSchoolsWithoutStudents);
                }
              }}
              checkboxes
            />
          ) : null}
        </span>
        <DropdownButton
          text="Actions"
          options={[
            {
              label: "Export to CSV",
              value: "export-to-csv",
            },
            { label: "Snapshot Grades", value: "snapshot-grades" },
          ]}
          onSelect={async (value) => {
            switch (value) {
              case "export-to-csv":
                if (schools.data) {
                  exportToCsv(
                    "schools.csv",
                    schools.data.map((school) => ({
                      name: school.name,
                      numberOfStudents: school.numberOfStudents,
                      estimatedOrderTotal:
                        (school.numberOfStudents ?? 0) * 450 + 3500,
                      paymentMethod: school.paymentMethod?.last4
                        ? `Card ending in ${school.paymentMethod.last4}`
                        : school.paymentMethod
                        ? "Stripe Credit"
                        : "None",
                      address: [
                        school.address?.line1,
                        school.address?.line2,
                        `${school.address?.city} ${school.address?.state} ${school.address?.zip}`,
                      ]
                        .filter(Boolean)
                        .join("\n"),
                      headLatinTeacher: users.data?.data.find(
                        (user) => user.id === school.headLatinTeacherId
                      )?.email,
                      examAdministrator: users.data?.data.find(
                        (user) => user.id === school.examAdministratorId
                      )?.email,
                    }))
                  );
                }
                break;
              case "snapshot-grades":
                if (
                  window.confirm(
                    "Are you sure you want to snapshot grades? No grades will be automatically calculated at this point, any additional grades will need to be manually entered.\n\nThis will allow percentiles to be calculated."
                  )
                ) {
                  const res = await snapshotScores.mutateAsync({ body: {} });
                  alert(res.message);
                }
            }
          }}
        />
      </Row>
      {schools.isLoading || users.isLoading ? <Loader /> : <SchoolsTable />}
      <StudentsModal
        schoolId={viewingStudentsForSchool}
        onClose={() => setViewingStudentsForSchool(null)}
      />
    </PageChrome>
  );
}

export default Schools;

function StudentsModal({
  schoolId,
  onClose,
}: {
  schoolId: string | null;
  onClose: () => unknown;
}) {
  const students = useApiQuery(
    "/students",
    {},
    { enabled: schoolId !== null },
    { schoolId: schoolId ?? "" }
  );

  return (
    <Modal open={schoolId !== null} close={onClose}>
      <Column
        style={{
          flex: 1,
          gap: 8,
        }}
      >
        <Text textStyle="headingXS">Students</Text>
        {students.isLoading ? (
          <Loader />
        ) : (
          <table
            style={{
              width: "100%",
              borderCollapse: "collapse",
            }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    paddingTop: 14,
                    paddingBottom: 14,
                    paddingLeft: 24,
                    paddingRight: 24,
                    borderTop: "1px solid #E5E5E5",
                    borderBottom: "1px solid #E5E5E5",
                    textAlign: "start",
                  }}
                >
                  <Text textStyle="strong">Name</Text>
                </th>
                <th
                  style={{
                    paddingTop: 14,
                    paddingBottom: 14,
                    paddingLeft: 24,
                    paddingRight: 24,
                    borderTop: "1px solid #E5E5E5",
                    borderBottom: "1px solid #E5E5E5",
                    textAlign: "start",
                  }}
                >
                  <Text textStyle="strong">Score</Text>
                </th>
              </tr>
            </thead>
            <tbody>
              {students.data?.data.map((student) => (
                <tr key={student.id}>
                  <td
                    style={{
                      paddingTop: 14,
                      paddingBottom: 14,
                      paddingLeft: 24,
                      paddingRight: 24,
                      borderTop: "1px solid #E5E5E5",
                    }}
                  >
                    <Text>
                      {student.firstName} {student.lastName}
                    </Text>
                  </td>
                  <td
                    style={{
                      paddingTop: 14,
                      paddingBottom: 14,
                      paddingLeft: 24,
                      paddingRight: 24,
                      borderTop: "1px solid #E5E5E5",
                    }}
                  >
                    <Text>
                      {student.score
                        ? `${student.score.correctlyFilledIn} / ${student.score.numberOfQuestions}`
                        : null}
                    </Text>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </Column>
    </Modal>
  );
}
