const displayDatetime = (date: Date | null | undefined | string): string => {
  if (!date) {
    return "";
  } else {
    const dateAsDate = new Date(date);

    const month = dateAsDate.toLocaleString("default", { month: "short" });
    const day = dateAsDate.getDate();
    const year = dateAsDate.getFullYear();
    const time = dateAsDate.toLocaleString("default", {
      hour: "numeric", // This will display the hour without a leading zero
      minute: "2-digit",
      hour12: true, // Ensures the time is in 12-hour format with AM/PM
    });

    return `${month} ${day}, ${year} ${time}`;
  }
};

export default displayDatetime;
