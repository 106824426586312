import { useNavigate, useParams } from "@tanstack/react-router";
import {
  Accordion,
  Button,
  Checkbox,
  DropdownMenu,
  Input,
  Loader,
  Row,
  Text,
} from "@gradience/ui";
import PageChrome from "../../components/page-chrome";
import { useApiDelete, useApiPut, useApiQuery } from "../../lib/api";
import { ReactNode, useMemo, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import styled from "styled-components";
import lunr from "lunr";

const CheckboxItem = styled.div<{ selected: boolean }>`
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 16px;
  border-radius: 12px;
  cursor: pointer;
  background-color: ${({ selected, theme }) =>
    selected ? theme.colors.surface.subdued : theme.colors.transparent};
  border: ${({ selected, theme }) =>
    selected
      ? `1px solid ${theme.colors.border.subdued}`
      : `1px solid ${theme.colors.transparent}`};
  &:hover {
    background-color: ${({ theme }) => theme.colors.surface.subdued};
  }
`;

const ChapterDetail = (): ReactNode => {
  const params = useParams({ from: "/curricula/$curriculumId/$chapterId" });
  const [_chapterName, setChapterName] = useState<string>();

  const chapters = useApiQuery(
    "/chapters",
    {},
    {},
    {
      curriculumIds: [params.curriculumId],
    }
  );
  const chapter = chapters.data?.data.find(
    (_chapter) => _chapter.id === params.chapterId
  );

  const concepts = useApiQuery(
    "/concepts",
    {},
    {
      enabled: chapters.isSuccess,
    }
  );

  const chapterName = _chapterName || chapter?.name;
  const queryClient = useQueryClient();
  const updateChapter = useApiPut(
    "/chapters/:id",
    {
      id: params.chapterId,
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["chapters"]);
      },
    }
  );
  const navigate = useNavigate();
  const deleteChapter = useApiDelete(
    "/chapters/:id",
    {
      id: params.chapterId,
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["chapters"]);
        navigate({
          to: "/curricula/$id",
          params: {
            id: params.curriculumId,
          },
        });
      },
    }
  );

  const conceptGroups = useApiQuery("/concept-groups", {});
  const [_selectedConcepts, setSelectedConcepts] = useState<string[]>([]);
  const selectedConcepts = useMemo(
    () =>
      _selectedConcepts.length > 0
        ? _selectedConcepts
        : chapter?.conceptIds ?? [],
    [_selectedConcepts, chapter?.conceptIds]
  );

  const loading = updateChapter.isLoading;

  const index = useMemo(() => {
    if (conceptGroups.data?.data) {
      return lunr(function () {
        this.ref("id");

        this.field("slug");
        this.field("name");

        for (const group of conceptGroups.data.data) {
          for (const concept of group.concepts) {
            if (!selectedConcepts.includes(concept.id)) {
              this.add(concept);
            }
          }
        }
      });
    } else {
      return null;
    }
  }, [conceptGroups.data?.data, selectedConcepts]);

  const [searchResults, setSearchResults] = useState<string[]>([]);
  const [spanRef, setSpanRef] = useState<HTMLSpanElement | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>("");

  return (
    <PageChrome>
      {concepts.isInitialLoading ||
      chapters.isInitialLoading ||
      conceptGroups.isInitialLoading ? (
        <Loader />
      ) : (
        <>
          <Row gap={8}>
            <Text
              textStyle="headingLarge"
              style={{
                flex: 1,
              }}
            >
              Chapter
            </Text>
            <Button
              type="button"
              text="Cancel"
              linkProps={{
                to: "/curricula/$curriculumId",
                params: {
                  curriculumId: params.curriculumId,
                },
              }}
              disabled={loading}
            />
            <Button
              type="button"
              variant="primary"
              text="Save"
              onPress={() => {
                updateChapter.mutate({
                  body: {
                    name: chapterName ?? "",
                    conceptIds: selectedConcepts,
                  },
                });
              }}
              loading={loading}
            />
          </Row>
          <Input value={chapterName ?? ""} setValue={setChapterName} />

          <Text textStyle="headingSmall">Concepts</Text>
          <span
            style={{
              position: "relative",
            }}
            ref={setSpanRef}
          >
            <Input
              value={searchQuery}
              placeholder="Search concepts"
              setValue={(value) => {
                setSearchQuery(value);
                // Lunr throws an error if the query is e.g. "-"
                let results: lunr.Index.Result[];
                try {
                  results = index?.search(value) ?? [];
                } catch (e) {
                  return;
                }
                setSearchResults(
                  results
                    .sort((a, b) => b.score - a.score)
                    .slice(0, 5)
                    .map((result) => result.ref)
                );
              }}
              inputProps={{
                autoComplete: "off",
                onFocus: (event) => {
                  let results: lunr.Index.Result[];
                  try {
                    results = index?.search(event.target.value) ?? [];
                  } catch (e) {
                    return;
                  }
                  setSearchResults(
                    results
                      .sort((a, b) => b.score - a.score)
                      .slice(0, 5)
                      .map((result) => result.ref)
                  );
                },
                onBlur: () => {
                  setSearchResults([]);
                },
              }}
            />
            {searchResults.length ? (
              <DropdownMenu
                setClosed={() => setSearchResults([])}
                options={searchResults.map((result) => {
                  const concept = concepts.data?.data.find(
                    (concept) => concept.id === result
                  );
                  return {
                    label: concept?.name ?? concept?.slug ?? "",
                    value: result,
                  };
                })}
                referenceElement={spanRef}
                onSelect={(value) => {
                  setSelectedConcepts([...selectedConcepts, value]);
                  setSearchResults([]);
                  setSearchQuery("");
                }}
              />
            ) : null}
          </span>
          <Text>
            {selectedConcepts
              .map((conceptId) => {
                const concept = concepts.data?.data.find(
                  (concept) => concept.id === conceptId
                );
                return concept?.slug ?? concept?.name;
              })
              .join(", ")}
          </Text>
          {conceptGroups.data?.data.map((group) => {
            return (
              <Accordion key={group.id} title={group.name}>
                {group.concepts.map((concept) => (
                  <CheckboxItem
                    style={{
                      padding: "9px 17px",
                    }}
                    selected={selectedConcepts.includes(concept.id)}
                    onClick={() => {
                      if (selectedConcepts.includes(concept.id)) {
                        setSelectedConcepts(
                          selectedConcepts.filter((x) => x !== concept.id)
                        );
                      } else {
                        setSelectedConcepts([...selectedConcepts, concept.id]);
                      }
                    }}
                    key={concept.id}
                  >
                    <Checkbox value={selectedConcepts.includes(concept.id)} />
                    <Text>{concept.name}</Text>
                  </CheckboxItem>
                ))}
              </Accordion>
            );
          })}
          <Button
            type="button"
            style={{
              alignSelf: "flex-start",
            }}
            variant="primary"
            text="Delete chapter"
            onPress={() => {
              if (
                window.confirm(
                  "Are you sure you want to delete this chapter? This cannot be undone."
                )
              ) {
                deleteChapter.mutate({
                  id: params.chapterId,
                });
              }
            }}
            loading={deleteChapter.isLoading}
          />
        </>
      )}
    </PageChrome>
  );
};

export default ChapterDetail;
