import { useMutation } from "@tanstack/react-query";
import useApi, { getToken } from "./api";

interface UploadResponse {
  message: string;
}

const uploadTestScanPageImage = async ({
  file,
  testId,
  api,
}: {
  file: File;
  testId: string;
  api: ReturnType<typeof useApi>;
}): Promise<UploadResponse> => {
  const formData = new FormData();
  formData.append("file", file);

  if (!api) {
    throw new Error("API is initializing.");
  }

  const response = await api.post<UploadResponse>(
    `/test-scan-pages/${testId}/image`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );

  return response.data;
};

export const useUploadTestScanPageImage = (testId: string) => {
  const api = useApi();

  return useMutation((file: File) =>
    uploadTestScanPageImage({ file, testId, api })
  );
};
