import { Text, Loader, useDesignTokens, Row, Button } from "@gradience/ui";
import PageChrome from "../../components/page-chrome";
import { useApiPost, useApiQuery } from "../../lib/api";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Curriculum } from "@gradience/api-types";
import { useMemo } from "react";
import { Link } from "@tanstack/react-router";

const columnHelper = createColumnHelper<Curriculum>();

function Curricula() {
  const curricula = useApiQuery("/curricula", {});

  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        header: "Name",
        cell: (row) => (
          <Text>
            <Link
              to="/curricula/$id"
              params={{
                id: row.row.original.id,
              }}
              style={{
                color: "inherit",
                textDecoration: "none",
              }}
            >
              {row.getValue()}
            </Link>
          </Text>
        ),
      }),
    ],
    []
  );

  const { getHeaderGroups, getRowModel } = useReactTable({
    columns,
    data: curricula.data?.data ?? [],
    getCoreRowModel: getCoreRowModel(),
  });

  const createCurriculumMutation = useApiPost("/curricula", {
    onSuccess: () => {
      curricula.refetch();
    },
  });

  const designTokens = useDesignTokens();
  const CurriculaTable = () => (
    <table
      style={{
        width: "100%",
        borderCollapse: "collapse",
      }}
    >
      <thead>
        {getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th
                key={header.id}
                style={{
                  paddingTop: 14,
                  paddingBottom: 14,
                  paddingLeft: 24,
                  paddingRight: 24,
                  borderTop: `1px solid ${designTokens.colors.border.subdued}`,
                  borderBottom: `1px solid ${designTokens.colors.border.subdued}`,
                  textAlign: "start",
                }}
              >
                <Text textStyle="strong">
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </Text>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {getRowModel().rows?.map((row) => (
          <tr key={row.id}>
            {row.getVisibleCells()?.map((cell) => (
              <td
                key={cell.id}
                style={{
                  paddingTop: 14,
                  paddingBottom: 14,
                  paddingLeft: 24,
                  paddingRight: 24,
                  borderTop: `1px solid ${designTokens.colors.border.subdued}`,
                }}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );

  return (
    <PageChrome>
      <Row
        style={{
          paddingTop: 11,
          paddingBottom: 11,
          gap: 8,
        }}
      >
        <Text
          textStyle="headingLarge"
          style={{
            flexGrow: 1,
          }}
        >
          Curricula
        </Text>
      </Row>
      {curricula.isInitialLoading ? (
        <Loader />
      ) : (
        <>
          <span
            style={{
              flex: 1,
            }}
          >
            <CurriculaTable />
          </span>
          <Button
            onPress={() => {
              const name = window.prompt("Pool Name", "Unnamed Pool");
              if (name) {
                createCurriculumMutation.mutate({
                  body: {
                    name,
                  },
                });
              }
            }}
            text="New Curriculum"
            disabled={createCurriculumMutation.isLoading}
            style={{
              alignSelf: "flex-start",
            }}
          />
        </>
      )}
    </PageChrome>
  );
}

export default Curricula;
