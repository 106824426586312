import {
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {
  LOGGED_IN_USER_KEY,
  UseMutationOptionsForEndpoint,
  useApiPost,
} from "./api";
import { AdminSession } from "@gradience/api-types";
import jwtDecode from "jwt-decode";
import config from "./config";

const { REACT_APP_OFFLINE_DEVELOPMENT_MODE } = config;

export const getUserFromToken = (token: string) =>
  jwtDecode<AdminSession>(token);
export const getToken = () => localStorage.getItem("token");
export const setToken = (token: string | null) =>
  token
    ? localStorage.setItem("token", token)
    : localStorage.removeItem("token");

export const useLogin = (
  options?: UseMutationOptions<
    {
      token: string;
    },
    unknown,
    { body: { username: string; password: string } }
  >
) => {
  const queryClient = useQueryClient();
  return useApiPost("/auth/admin/login", {
    onSuccess: ({ token }) => {
      setToken(token);
      queryClient.setQueryData(LOGGED_IN_USER_KEY, getUserFromToken(token));
    },
    ...options,
  });
};

export const useRegister = (
  options?: UseMutationOptions<
    { token: string },
    unknown,
    {
      body: {
        email: string;
        password: string;
        firstName: string;
        lastName: string;
        schoolName: string;
        roleAtSchool: string;
      };
    }
  >
) => {
  const queryClient = useQueryClient();

  return useApiPost("/auth/register", {
    mutationKey: ["register"],
    onSuccess: ({ token }) => {
      setToken(token);
      queryClient.setQueryData(LOGGED_IN_USER_KEY, getUserFromToken(token));
    },

    ...options,
  });
};

export const useVerifyEmail = () => {
  const queryClient = useQueryClient();

  return useApiPost("/auth/verify-email", {
    mutationKey: ["register"],
    onSuccess: ({ token }) => {
      setToken(token);
      queryClient.setQueryData(LOGGED_IN_USER_KEY, getUserFromToken(token));
    },
  });
};

export const useLogout = (options?: UseMutationOptions) => {
  const queryClient = useQueryClient();

  return useMutation(
    LOGGED_IN_USER_KEY,
    async () => {
      setToken(null);

      return queryClient.resetQueries();
    },
    {
      networkMode: "always",
      ...options,
    }
  );
};

export const getLoggedInUser = () => {
  const token = getToken();
  if (token) {
    return getUserFromToken(token);
  } else {
    return null;
  }
};

export const useLoggedInUser = (
  options?: UseQueryOptions<AdminSession | null>
) =>
  useQuery<AdminSession | null>(LOGGED_IN_USER_KEY, getLoggedInUser, {
    ...(REACT_APP_OFFLINE_DEVELOPMENT_MODE
      ? { networkMode: options?.networkMode ?? "always" }
      : {}),
    ...options,
  });
