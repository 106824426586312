const exportToCsv = <T extends Record<string, string | number | undefined>>(
  filename: string,
  rows: T[]
) => {
  const processRow = (row: T) => {
    const values = [];
    for (const key in row) {
      if (row.hasOwnProperty(key)) {
        const value = row[key];
        values.push(`"${value}"`);
      }
    }
    return values.join(",") + "\n";
  };

  const csvFile = rows.reduce((acc, row) => acc + processRow(row), "");

  const blob = new Blob([csvFile], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  const url = URL.createObjectURL(blob);
  link.setAttribute("href", url);
  link.setAttribute("download", filename);
  link.style.visibility = "hidden";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export default exportToCsv;
