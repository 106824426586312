import { useNavigate, useParams } from "@tanstack/react-router";
import { Button, Column, Input, Loader, Row, Text } from "@gradience/ui";
import PageChrome from "../../components/page-chrome";
import { useApiDelete, useApiPut, useApiQuery } from "../../lib/api";
import { ReactNode, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import MarkdownInput from "../../components/markdown-input";

const SupplementalConceptDetail = (): ReactNode => {
  const params = useParams({ from: "/supplemental-concepts/$id" });

  const supplementalConceptGroups = useApiQuery(
    "/supplemental-concept-groups",
    {}
  );
  const supplementalConcept = supplementalConceptGroups.data?.data
    .flatMap((group) => group.concepts)
    .find((concept) => concept.id === params.id);
  const groupId = supplementalConceptGroups.data?.data.find((group) =>
    group.concepts.some((concept) => concept.id === params.id)
  )?.id;

  const [_conceptName, setConceptName] = useState<string>();
  const conceptName = _conceptName || supplementalConcept?.name;
  const [_studyGuideText, setStudyGuideText] = useState<string>();
  const studyGuideText = _studyGuideText || supplementalConcept?.studyGuideText;

  const queryClient = useQueryClient();

  const updateSupplementalConcept = useApiPut(
    "/supplemental-concepts/:id",
    {
      id: params.id,
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["supplemental-concepts"]);
      },
    }
  );

  const navigate = useNavigate();
  const deleteSupplementalConcept = useApiDelete(
    "/supplemental-concepts/:id",
    {
      id: params.id,
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["supplemental-concepts"]);
        navigate({
          to: "/supplemental-concepts",
        });
      },
    }
  );

  const loading =
    updateSupplementalConcept.isLoading || deleteSupplementalConcept.isLoading;

  return (
    <PageChrome>
      {supplementalConceptGroups.isInitialLoading ? (
        <Loader />
      ) : (
        <>
          <Row gap={8}>
            <Text
              textStyle="headingLarge"
              style={{
                flex: 1,
              }}
            >
              Supplemental Concept
            </Text>
            <Button
              type="button"
              text="Cancel"
              linkProps={{
                to: "/supplemental-concepts",
              }}
              disabled={loading}
            />
            <Button
              type="button"
              variant="primary"
              text="Save"
              onPress={() => {
                if (!supplementalConcept || !groupId) {
                  return;
                }
                updateSupplementalConcept.mutate({
                  body: {
                    name: conceptName ?? "Untitled",
                    studyGuideText: studyGuideText ?? "",
                    groupId,
                  },
                });
              }}
              loading={loading}
            />
          </Row>
          <Input label="Name" value={conceptName} setValue={setConceptName} />
          <Column gap={8}>
            <Text textStyle="strong">Study Guide Text</Text>
            <MarkdownInput
              value={studyGuideText ?? ""}
              setValue={setStudyGuideText}
            />
          </Column>

          <Button
            type="button"
            style={{
              alignSelf: "flex-start",
            }}
            variant="primary"
            text="Delete supplemental concept"
            onPress={() => {
              if (
                window.confirm(
                  "Are you sure you want to delete this supplemental concept? This cannot be undone."
                )
              ) {
                deleteSupplementalConcept.mutate({
                  id: params.questionId,
                });
              }
            }}
            loading={loading}
          />
        </>
      )}
    </PageChrome>
  );
};

export default SupplementalConceptDetail;
