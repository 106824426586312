import { StrictMode } from "react";
import { DesignTokensProvider } from "@gradience/ui";
import ReactDOM from "react-dom/client";
import {
  RouterProvider,
  Router,
  Route,
  RootRoute,
  Link,
} from "@tanstack/react-router";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import LogIn from "./routes/auth/log-in";
import SignUp from "./routes/auth/sign-up";
import RequestPasswordReset from "./routes/auth/request-password-reset";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ResetPassword from "./routes/auth/reset-password";
import Root from "./routes/root";
import NotFoundPage from "./routes/not-found-page";
import Schools from "./routes/schools";
import QuestionPools from "./routes/questions/index";
import Questions from "./routes/questions/questions";
import QuestionDetail from "./routes/questions/question";
import Curricula from "./routes/curricula";
import CurriculumPage from "./routes/curricula/curriculum";
import ChapterDetail from "./routes/curricula/chapter";
import Concepts from "./routes/concepts";
import ConceptDetail from "./routes/concepts/concept";
import ConceptGroupDetail from "./routes/concepts/group";
import SupplementalConcepts from "./routes/supplemental-concepts";
import SupplementalConceptDetail from "./routes/supplemental-concepts/concept";
import SupplementalConceptGroupDetail from "./routes/supplemental-concepts/group";
import Tests from "./routes/tests";
import TestDetail from "./routes/tests/test";
import TestDatesOverrideDetail from "./routes/test-dates-overrides/override";
import GradingProgress from "./routes/grading-progress";
import ManualGrading from "./routes/manual-grading";
import ManualGradingIssues from "./routes/manual-grading/issues";
import ManualGradingErrors from "./routes/manual-grading/errors";

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// Create a root route
const rootRoute = new RootRoute({
  component: Root,
});

const homeRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/",
  component: () => (
    <div>
      <h1>Home</h1>
      <Link to={"/log-in"}>Log in</Link>
    </div>
  ),
});

const notFoundRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "*",
  component: NotFoundPage,
});

const logInRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/log-in",
  component: LogIn,
});

const signUpRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/sign-up",
  component: SignUp,
});

const requestPasswordResetRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/request-password-reset",
  component: RequestPasswordReset,
});

const schoolsRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/schools",
  component: Schools,
});

const questionRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/questions/$poolId/$questionId",
  component: QuestionDetail,
});

const questionsRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/questions/$poolId",
  component: Questions,
});

const questionPoolsRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/questions",
  component: QuestionPools,
});

const gradingProgressRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/grading-progress",
  component: GradingProgress,
});

export const manualGradingRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/manual-grading",
  component: ManualGrading,
});

export const manualGradingIssuesRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/manual-grading/issues",
  component: ManualGradingIssues,
  validateSearch: (search: Record<string, unknown>) => {
    return {
      gradingPage:
        typeof search.gradingPage === "number" ? search.gradingPage : 0,
    };
  },
});

export const manualGradingErrorsRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/manual-grading/errors",
  component: ManualGradingErrors,
  validateSearch: (search: Record<string, unknown>) => {
    return {
      gradingPage:
        typeof search.gradingPage === "number" ? search.gradingPage : 0,
    };
  },
});

export const curriculaRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/curricula",
  component: Curricula,
});

export const curriculumRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/curricula/$id",
  component: CurriculumPage,
});

export const chapterRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/curricula/$curriculumId/$chapterId",
  component: ChapterDetail,
});

export const conceptsRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/concepts",
  component: Concepts,
});

export const conceptGroupRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/concepts/groups/$groupId",
  component: ConceptGroupDetail,
});

export const conceptRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/concepts/$id",
  component: ConceptDetail,
});

export const supplementalConceptsRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/supplemental-concepts",
  component: SupplementalConcepts,
});

export const supplementalConceptGroupRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/supplemental-concepts/groups/$groupId",
  component: SupplementalConceptGroupDetail,
});

export const supplementalConceptRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/supplemental-concepts/$id",
  component: SupplementalConceptDetail,
});

export const testsRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/tests",
  component: Tests,
});

export const testRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/tests/$slug",
  component: TestDetail,
});

export const resetPasswordRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/reset-password",
  component: ResetPassword,
  validateSearch: (search: Record<string, unknown>) => {
    return {
      token: typeof search.token === "string" ? search.token : "",
      initialSetup: search.initialSetup !== undefined,
    };
  },
});

export const testDatesOverrideRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/test-dates-overrides/$id",
  component: TestDatesOverrideDetail,
});

const routeTree = rootRoute.addChildren([
  notFoundRoute,
  logInRoute,
  signUpRoute,
  requestPasswordResetRoute,
  resetPasswordRoute,
  schoolsRoute,
  questionRoute,
  questionPoolsRoute,
  gradingProgressRoute,
  manualGradingRoute,
  manualGradingIssuesRoute,
  manualGradingErrorsRoute,
  questionsRoute,
  curriculumRoute,
  curriculaRoute,
  chapterRoute,
  homeRoute,
  conceptsRoute,
  conceptGroupRoute,
  conceptRoute,
  supplementalConceptsRoute,
  supplementalConceptGroupRoute,
  supplementalConceptRoute,
  testsRoute,
  testRoute,
  testDatesOverrideRoute,
]);

export const router = new Router({ routeTree });

declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

const queryClient = new QueryClient({
  logger: {
    log: () => {},
    warn: () => {},
    error: () => {},
  },
});

const rootElement = document.getElementById("root") as HTMLElement;
if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);

  root.render(
    <StrictMode>
      <QueryClientProvider client={queryClient}>
        <DesignTokensProvider themeColor={"#000000"}>
          <RouterProvider router={router} />
        </DesignTokensProvider>
      </QueryClientProvider>
    </StrictMode>
  );
}
