import {
  Text,
  useDesignTokens,
  Row,
  Column,
  Loader,
  Modal,
  ClickableText,
  Icon,
  Button,
} from "@gradience/ui";
import Konva from "konva";
import { Stage, Layer, Transformer, Rect } from "react-konva";
import PageChrome from "../../components/page-chrome";
import { useApiDelete, useApiPost, useApiQuery } from "../../lib/api";
import { useEffect, useMemo, useRef, useState } from "react";
import { FiducialAnnotation } from "@gradience/api-types/src/fiducial-annotation";
import { useUploadTestScanPageImage } from "../../lib/use-upload-test-scan-page-image";
import { useGetGradeReport } from "../../lib/use-get-grade-report";

const displayNames = {
  "divide-pages": "Divide uploads into pages",
  "identify-fiducial-marks": "Identify fiducial marks",
  "read-qr-codes": "Read QR codes",
  "extract-answer-circles": "Extract answer circles",
  "classify-answer-circles": "Classify answer circles",
};

function GradingProgress() {
  const testGradingProgress = useApiQuery("/grading-progress", {
    refetchOnWindowFocus: false,
  });

  const getParamsForFailed = (stepName: string) => {
    switch (stepName) {
      case "identify-fiducial-marks":
        return { failedIdentifyingFiducials: "true" };
      case "read-qr-codes":
        return { errorReadingQrCode: "true" };
      default:
        return {} as Record<string, string>;
    }
  };

  const getGradeReport = useGetGradeReport();

  return (
    <PageChrome>
      <Row
        style={{
          paddingTop: 11,
          paddingBottom: 11,
          gap: 8,
        }}
      >
        <Text
          textStyle="headingLarge"
          style={{
            flexGrow: 1,
          }}
        >
          Grading Progress
        </Text>
        <Button
          onPress={async () => {
            const file = await getGradeReport.mutateAsync();
            const url = window.URL.createObjectURL(new Blob([file]));

            // Create a link to download it
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "grade-report.csv"); // Name the download file
            document.body.appendChild(link);

            // Programmatically click the link to download the file
            link.click();

            // Clean up by removing the link and revoking the URL
            link.parentNode?.removeChild(link);
            window.URL.revokeObjectURL(url);
          }}
          text="Download Grade Report"
        />
      </Row>
      {testGradingProgress.data ? (
        testGradingProgress.data.data.map((step) => (
          <Column gap={8} key={step.name}>
            <Text textStyle="headingMedium">{displayNames[step.name]}</Text>
            <ProgressTable
              {...step.progress}
              paramsForFailed={getParamsForFailed(step.name)}
            />
          </Column>
        ))
      ) : (
        <Loader />
      )}
    </PageChrome>
  );
}

const ProgressTable = ({
  processed,
  failed,
  unprocessed,
  paramsForFailed,
}: {
  processed: number;
  failed: number;
  unprocessed: number;
  paramsForFailed?: Record<string, string>;
}) => {
  const designTokens = useDesignTokens();
  const [viewingFailed, setViewingFailed] = useState(false);
  const [page, setPage] = useState(0);

  const PAGE_SIZE = 1;

  const failedScans = useApiQuery(
    "/test-scan-pages",
    {},
    { enabled: viewingFailed, refetchOnWindowFocus: false },
    {
      ...paramsForFailed,
      distinctByPageNumberAndStudent: "false",
      take: PAGE_SIZE.toString(),
      skip: (page * PAGE_SIZE).toString(),
    }
  );
  const postFiducialAnnotations = useApiPost(
    "/test-scan-pages/:id/fiducial-annotations",
    {},
    { id: failedScans.data?.data[0]?.id ?? "" }
  );
  const deleteFailedScan = useApiDelete("/test-scan-pages/:id", {
    id: failedScans.data?.data[0]?.id ?? "",
  });

  const containerRef = useRef<HTMLDivElement>(null);

  const imageUrl = useMemo(() => {
    return failedScans.data?.data[0]?.imageUrl
      ? failedScans.data.data[0].imageUrl
      : "";
  }, [failedScans.data?.data]);

  const [imageWidth, setImageWidth] = useState<number>();
  const [imageHeight, setImageHeight] = useState<number>();
  const [bracketPositions, setBracketPositions] = useState<
    FiducialAnnotation[]
  >([]);

  const uploadFile = useUploadTestScanPageImage(
    failedScans.data?.data[0]?.id ?? ""
  );

  const scaleFiducialValueX = (value: number) => {
    return `${imageWidth ? (value / imageWidth) * 100 : 0}%`;
  };
  const scaleFiducialValueY = (value: number) => {
    return `${imageHeight ? (value / imageHeight) * 100 : 0}%`;
  };

  return (
    <>
      <table
        style={{
          borderCollapse: "collapse",
          width: "100%",
        }}
      >
        <thead>
          <tr>
            {["processed", "failed", "unprocessed"].map((header) => (
              <th
                key={header}
                style={{
                  paddingTop: 14,
                  paddingBottom: 14,
                  paddingLeft: 24,
                  paddingRight: 24,
                  borderTop: `1px solid ${designTokens.colors.border.subdued}`,
                  borderBottom: `1px solid ${designTokens.colors.border.subdued}`,
                  textAlign: "start",
                }}
              >
                {header === "failed" && failed > 0 ? (
                  <ClickableText onClick={() => setViewingFailed(true)}>
                    {header}
                  </ClickableText>
                ) : (
                  <Text textStyle="strong">{header}</Text>
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            {[processed, failed, unprocessed].map((value, index) => (
              <td
                key={index}
                style={{
                  paddingTop: 14,
                  paddingBottom: 14,
                  paddingLeft: 24,
                  paddingRight: 24,
                  borderTop: `1px solid ${designTokens.colors.border.subdued}`,
                }}
              >
                <Text>{value}</Text>
              </td>
            ))}
          </tr>
        </tbody>
      </table>
      <Modal
        open={viewingFailed}
        close={() => setViewingFailed(false)}
        style={{
          width: "100%",
        }}
      >
        {failedScans.data ? (
          <Column
            gap={8}
            style={{
              width: "100%",
            }}
          >
            <Row
              gap={8}
              style={{
                alignItems: "center",
              }}
            >
              <Column gap={4}>
                <Row
                  gap={8}
                  style={{
                    alignItems: "center",
                  }}
                >
                  <Icon
                    name="chevron-left"
                    onClick={() => setPage(Math.max(0, page - 1))}
                  />
                  <Text textStyle="strong">Page {page + 1}</Text>
                  <Icon
                    name="chevron-right"
                    onClick={() => setPage(page + 1)}
                  />
                </Row>
                <input
                  type="file"
                  onChange={(event) => {
                    const file = event.target.files?.[0];
                    if (file) {
                      uploadFile.mutate(file);
                    }
                  }}
                />
              </Column>
              <span
                style={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: 8,
                }}
              >
                <Button
                  onPress={() => {
                    deleteFailedScan.mutate({});
                  }}
                  text="Delete"
                />
                <Button
                  onPress={() => {
                    postFiducialAnnotations.mutate({
                      body: {
                        annotations: bracketPositions,
                      },
                    });
                  }}
                  text="Add fiducial annotations"
                />
              </span>
            </Row>
            {failedScans.data.data[0] ? (
              <div
                ref={containerRef}
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexGrow: 1,
                  position: "relative",
                }}
              >
                <img
                  src={imageUrl}
                  alt="Failed scan"
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                  onLoad={(event) => {
                    setImageWidth(event.currentTarget.naturalWidth);
                    setImageHeight(event.currentTarget.naturalHeight);
                  }}
                />
                <ResizableBox
                  setBracketPositions={setBracketPositions}
                  imageWidth={imageWidth ?? 0}
                />
                {failedScans.data.data[0].fiducialLocations?.map(
                  (fiducial, index) => (
                    <div
                      key={index}
                      style={{
                        top: scaleFiducialValueY(fiducial.y),
                        left: scaleFiducialValueX(fiducial.x),
                        width: scaleFiducialValueX(fiducial.width),
                        height: scaleFiducialValueY(fiducial.height),
                        border: "2px solid blue",
                        position: "absolute",
                      }}
                    />
                  )
                )}
              </div>
            ) : (
              <Text>No more failed scans to show</Text>
            )}
          </Column>
        ) : (
          <Loader />
        )}
      </Modal>
    </>
  );
};

const BOX_DISTANCE_FROM_TOP = 530;
const BOX_DISTANCE_FROM_SIDES = 720;
const BOX_HEIGHT = 1080;
const BOX_WIDTH = 4680;
const BOX_BORDER_RADIUS = 40;

const PAGE_WIDTH = 6220;
const PAGE_HEIGHT = 7920;

const BRACKET_1_DISTANCE_FROM_TOP = 619;
const BRACKET_1_DISTANCE_FROM_LEFT = 810;
const BRACKET_2_DISTANCE_FROM_TOP = 1460;
const BRACKET_2_DISTANCE_FROM_LEFT = 810;
const BRACKET_3_DISTANCE_FROM_TOP = 619;
const BRACKET_3_DISTANCE_FROM_LEFT = 5250;
const BRACKET_4_DISTANCE_FROM_TOP = 1460;
const BRACKET_4_DISTANCE_FROM_LEFT = 5250;

const BRACKET_SIZE = 60;

const ResizableBox = ({
  setBracketPositions,
  imageWidth,
}: {
  setBracketPositions: (positions: FiducialAnnotation[]) => unknown;
  imageWidth: number;
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const shapeRef = useRef<Konva.Rect>(null);
  const transformerRef = useRef<Konva.Transformer>(null);
  const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });

  // Calculate the scale based on actual height vs. standard page height
  const estimatedScale = containerSize.width / PAGE_WIDTH;

  // Calculate the box's dimensions and position based on the container's size
  const boxWidth = BOX_WIDTH * estimatedScale;
  const boxHeight = BOX_HEIGHT * estimatedScale;
  const boxX = BOX_DISTANCE_FROM_SIDES * estimatedScale;
  const boxY = BOX_DISTANCE_FROM_TOP * estimatedScale;

  const layerRef = useRef<Konva.Layer>(null);

  const [rectTransform, setRectTransform] = useState({
    x: boxX,
    y: boxY,
    width: boxWidth,
    height: boxHeight,
    rotation: 0,
  });

  useEffect(() => {
    if (containerRef.current) {
      const { width, height } = containerRef.current.getBoundingClientRect();
      setContainerSize({ width, height });
    }
  }, []);

  useEffect(() => {
    if (transformerRef.current && shapeRef.current) {
      transformerRef.current.nodes([shapeRef.current]);
      transformerRef.current.getLayer()?.batchDraw();
    }
  }, []);

  const scale = rectTransform.width / BOX_WIDTH;

  const [bracketsPos, setBracketsPos] = useState<{ x: number; y: number }[]>(
    []
  );

  useEffect(() => {
    const {
      x: boxX,
      y: boxY,
      width: boxWidth,
      height: boxHeight,
      rotation: boxRotation,
    } = rectTransform;

    let scale = boxWidth / BOX_WIDTH;

    const boxTheta = boxRotation * (Math.PI / 180);

    const getBracketLocation = (ogBracketLocation: {
      x: number;
      y: number;
    }) => {
      const OG_POINT_LOC = [
        ogBracketLocation.x - BOX_DISTANCE_FROM_SIDES,
        ogBracketLocation.y - BOX_DISTANCE_FROM_TOP,
      ];
      const rotatedBracketLoc = [
        Math.cos(boxTheta) * OG_POINT_LOC[0] -
          Math.sin(boxTheta) * OG_POINT_LOC[1],
        Math.sin(boxTheta) * OG_POINT_LOC[0] +
          Math.cos(boxTheta) * OG_POINT_LOC[1],
      ];

      return {
        x: rotatedBracketLoc[0] * scale + boxX,
        y: rotatedBracketLoc[1] * scale + boxY,
      };
    };

    const bracketPositions = [
      getBracketLocation({
        x: BRACKET_1_DISTANCE_FROM_LEFT,
        y: BRACKET_1_DISTANCE_FROM_TOP,
      }),
      getBracketLocation({
        x: BRACKET_2_DISTANCE_FROM_LEFT,
        y: BRACKET_2_DISTANCE_FROM_TOP,
      }),
      getBracketLocation({
        x: BRACKET_3_DISTANCE_FROM_LEFT,
        y: BRACKET_3_DISTANCE_FROM_TOP,
      }),
      getBracketLocation({
        x: BRACKET_4_DISTANCE_FROM_LEFT,
        y: BRACKET_4_DISTANCE_FROM_TOP,
      }),
    ];

    const imageScale = imageWidth / PAGE_WIDTH;
    const imageBracketPositions = bracketPositions.map((pos) => {
      return {
        x: pos.x / (containerSize.width / imageWidth),
        y: pos.y / (containerSize.width / imageWidth),
      };
    });

    setBracketPositions(
      imageBracketPositions.map((pos, index) => {
        return {
          y: pos.y - 40 * imageScale,
          x: pos.x - 40 * imageScale,
          width: (BRACKET_SIZE + 80) * imageScale,
          height: (BRACKET_SIZE + 80) * imageScale,
          fiducialType: (
            ["TOP_LEFT", "BOTTOM_LEFT", "TOP_RIGHT", "BOTTOM_RIGHT"] as const
          )[index],
        };
      })
    );

    setBracketsPos(bracketPositions);
  }, [
    containerSize.height,
    containerSize.width,
    rectTransform,
    estimatedScale,
    setBracketPositions,
    imageWidth,
  ]);

  return (
    <>
      <div
        ref={containerRef}
        style={{ top: 0, bottom: 0, left: 0, right: 0, position: "absolute" }}
      >
        {
          <Stage width={containerSize.width} height={containerSize.height}>
            <Layer ref={layerRef}>
              <Rect
                onTransformEnd={(event) => {
                  setRectTransform({
                    x: event.target.x(),
                    y: event.target.y(),
                    width: event.target.width() * event.target.scaleX(),
                    height: event.target.height() * event.target.scaleY(),
                    rotation: event.target.rotation(),
                  });
                }}
                onDragEnd={(event) => {
                  setRectTransform({
                    x: event.target.x(),
                    y: event.target.y(),
                    width: event.target.width() * event.target.scaleX(),
                    height: event.target.height() * event.target.scaleY(),
                    rotation: event.target.rotation(),
                  });
                }}
                x={boxX}
                y={boxY}
                width={boxWidth}
                height={boxHeight}
                stroke="limegreen"
                opacity={0.3}
                strokeWidth={4}
                draggable
                ref={shapeRef}
              />
              <Transformer
                ref={transformerRef}
                ignoreStroke
                keepRatio={false}
              />
            </Layer>
          </Stage>
        }
      </div>
      {bracketsPos.map((pos, index) => (
        <div
          key={index}
          style={{
            top: pos.y - 40 * scale,
            left: pos.x - 40 * scale,
            width: (BRACKET_SIZE + 80) * scale,
            height: (BRACKET_SIZE + 80) * scale,
            border: "2px solid red",
            position: "absolute",
          }}
        />
      ))}
    </>
  );
};

export default GradingProgress;
