var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import rehypeSanitize from "rehype-sanitize";
var MarkdownRenderer = function (_a) {
    var content = _a.content;
    return (_jsx(Markdown, __assign({ components: {
            ins: function (_a) {
                var children = _a.children;
                return (_jsx("span", __assign({ style: { textDecoration: "underline" } }, { children: children })));
            },
        }, remarkRehypeOptions: { allowDangerousHtml: true }, rehypePlugins: [
            rehypeRaw,
            [rehypeSanitize, { tagNames: ["b", "strong", "em", "ins", "u", "i"] }],
        ] }, { children: content })));
};
export default MarkdownRenderer;
