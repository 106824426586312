import { useNavigate, useParams } from "@tanstack/react-router";
import {
  Accordion,
  Button,
  Checkbox,
  Loader,
  Row,
  Text,
  useDesignTokens,
} from "@gradience/ui";
import PageChrome from "../../components/page-chrome";
import { useApiDelete, useApiPut, useApiQuery } from "../../lib/api";
import { ReactNode, useEffect, useMemo, useState } from "react";
import {
  BoldExtension,
  ItalicExtension,
  UnderlineExtension,
} from "remirror/extensions";
import {
  EditorComponent,
  Remirror,
  useRemirror,
  useHelpers,
} from "@remirror/react";
import { useQueryClient } from "@tanstack/react-query";
import styled from "styled-components";
import SearchInput from "../../components/search-input";

const CheckboxItem = styled.div<{ selected: boolean }>`
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 16px;
  border-radius: 12px;
  cursor: pointer;
  background-color: ${({ selected, theme }) =>
    selected ? theme.colors.surface.subdued : theme.colors.transparent};
  border: ${({ selected, theme }) =>
    selected
      ? `1px solid ${theme.colors.border.subdued}`
      : `1px solid ${theme.colors.transparent}`};
  &:hover {
    background-color: ${({ theme }) => theme.colors.surface.subdued};
  }
`;

function MarkdownPreview({
  setMarkdown,
}: {
  setMarkdown: (_: string) => unknown;
}) {
  const { getHTML } = useHelpers(true);

  const markdown = getHTML();

  useEffect(() => {
    setMarkdown(markdown);
  }, [markdown, setMarkdown]);

  return null;
}

const QuestionDetail = (): ReactNode => {
  const params = useParams({ from: "/questions/$poolId/$questionId" });
  const [markdown, setMarkdown] = useState<string>();

  const questions = useApiQuery("/questions", {});
  const question = questions.data?.data.find(
    (_question) => _question.id === params.questionId
  );

  const concepts = useApiQuery(
    "/concepts",
    {},
    {
      enabled: questions.isSuccess,
    }
  );
  const supplementalConceptGroups = useApiQuery(
    "/supplemental-concept-groups",
    {},
    {
      enabled: questions.isSuccess,
    }
  );
  const supplementalConcepts = supplementalConceptGroups.data?.data.flatMap(
    (group) => group.concepts
  );

  const questionContent = markdown || question?.text;
  const queryClient = useQueryClient();
  const updateQuestion = useApiPut(
    "/questions/:id",
    {
      id: params.questionId,
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["questions"]);
      },
    }
  );
  const navigate = useNavigate();
  const deleteQuestion = useApiDelete(
    "/questions/:id",
    {
      id: params.questionId,
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["questions"]);
        navigate({
          to: "/questions/$poolId",
          params: {
            poolId: params.poolId,
          },
        });
      },
    }
  );

  const conceptGroups = useApiQuery("/concept-groups", {});
  const [_selectedConcepts, setSelectedConcepts] = useState<string[]>([]);
  const selectedConcepts = useMemo(
    () =>
      _selectedConcepts.length > 0
        ? _selectedConcepts
        : question?.conceptIds ?? [],
    [_selectedConcepts, question?.conceptIds]
  );

  const answers = useApiQuery(
    "/answers",
    {},
    {},
    { questionIds: [params.questionId] }
  );

  const [_selectedSupplementalConcepts, setSelectedSupplementalConcepts] =
    useState<string[]>([]);
  const selectedSupplementalConcepts = useMemo(
    () =>
      _selectedSupplementalConcepts.length > 0
        ? _selectedSupplementalConcepts
        : question?.supplementalConceptIds ?? [],
    [_selectedSupplementalConcepts, question?.supplementalConceptIds]
  );

  type Answer = {
    text: string;
    isCorrect: boolean;
    id: string;
  };
  const [_answerA, setAnswerA] = useState<Answer>();
  const answerA = _answerA ?? question?.answers[0];
  const [_answerB, setAnswerB] = useState<Answer>();
  const answerB = _answerB ?? question?.answers[1];
  const [_answerC, setAnswerC] = useState<Answer>();
  const answerC = _answerC ?? question?.answers[2];
  const [_answerD, setAnswerD] = useState<Answer>();
  const answerD = _answerD ?? question?.answers[3];

  const designTokens = useDesignTokens();

  const loading = updateQuestion.isLoading;

  return (
    <PageChrome>
      {concepts.isInitialLoading ||
      questions.isInitialLoading ||
      supplementalConceptGroups.isInitialLoading ||
      conceptGroups.isInitialLoading ? (
        <Loader />
      ) : (
        <>
          <Row gap={8}>
            <Text
              textStyle="headingLarge"
              style={{
                flex: 1,
              }}
            >
              Question
            </Text>
            <Button
              type="button"
              text="Cancel"
              linkProps={{
                to: "/questions/$poolId",
                params: {
                  poolId: params.poolId,
                },
              }}
              disabled={loading}
            />
            <Button
              type="button"
              variant="primary"
              text="Save"
              onPress={() => {
                updateQuestion.mutate({
                  body: {
                    text: questionContent ?? "",
                    conceptIds: selectedConcepts,
                    supplementalConceptIds: selectedSupplementalConcepts,
                    answers: [
                      {
                        text: answerA?.text ?? "",
                        isCorrect: answerA?.isCorrect ?? false,
                      },
                      {
                        text: answerB?.text ?? "",
                        isCorrect: answerB?.isCorrect ?? false,
                      },
                      {
                        text: answerC?.text ?? "",
                        isCorrect: answerC?.isCorrect ?? false,
                      },
                      {
                        text: answerD?.text ?? "",
                        isCorrect: answerD?.isCorrect ?? false,
                      },
                    ],
                  },
                });
              }}
              loading={loading}
            />
          </Row>
          <MarkdownInput value={questionContent ?? ""} setValue={setMarkdown} />
          <Text
            textStyle="caption"
            style={{
              color: designTokens.colors.text.disabled,
            }}
          >
            Use ⌘ + B to bold and ⌘ + I to italicize and ⌘ + U to underline
          </Text>

          <Text textStyle="headingSmall">Answers</Text>
          <span
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "8px",
              height: "56px",
              alignItems: "center",
            }}
          >
            <Checkbox
              value={answerA?.isCorrect}
              onChange={(value) => {
                answerA && setAnswerA({ ...answerA, isCorrect: value });
              }}
            />
            <MarkdownInput
              value={answerA?.text ?? ""}
              setValue={(value) => {
                answerA && setAnswerA({ ...answerA, text: value });
              }}
            />
          </span>
          <span
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "8px",
              height: "56px",
              alignItems: "center",
            }}
          >
            <Checkbox
              value={answerB?.isCorrect}
              onChange={(value) => {
                answerB &&
                  setAnswerB({
                    ...answerB,
                    isCorrect: value,
                  });
              }}
            />
            <MarkdownInput
              value={answerB?.text ?? ""}
              setValue={(value) => {
                answerB && setAnswerB({ ...answerB, text: value });
              }}
            />
          </span>
          <span
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "8px",
              height: "56px",
              alignItems: "center",
            }}
          >
            <Checkbox
              value={answerC?.isCorrect}
              onChange={(value) => {
                answerC &&
                  setAnswerC({
                    ...answerC,
                    isCorrect: value,
                  });
              }}
            />
            <MarkdownInput
              value={answerC?.text ?? ""}
              setValue={(value) => {
                answerC && setAnswerC({ ...answerC, text: value });
              }}
            />
          </span>
          <span
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "8px",
              height: "56px",
              alignItems: "center",
            }}
          >
            <Checkbox
              value={answerD?.isCorrect}
              onChange={(value) => {
                answerD &&
                  setAnswerD({
                    ...answerD,
                    isCorrect: value,
                  });
              }}
            />
            <MarkdownInput
              value={answerD?.text ?? ""}
              setValue={(value) => {
                answerD && setAnswerD({ ...answerD, text: value });
              }}
            />
          </span>

          <Text textStyle="headingSmall">Concepts</Text>
          <SearchInput
            items={(conceptGroups.data?.data ?? []).flatMap((group) =>
              group.concepts.map((concept) => ({
                label: concept.name,
                fields: {
                  id: concept.id,
                  name: concept.name,
                  slug: concept.slug ?? "",
                },
                value: concept.id,
              }))
            )}
            selectedIds={selectedConcepts}
            indexFields={["name", "slug"]}
            identityField="id"
            onSelect={(value) =>
              setSelectedConcepts([...selectedConcepts, value])
            }
          />
          <Text>
            {selectedConcepts
              .map((conceptId) => {
                const concept = concepts.data?.data.find(
                  (concept) => concept.id === conceptId
                );
                return concept?.slug ?? concept?.name;
              })
              .join(", ")}
          </Text>
          {conceptGroups.data?.data.map((group) => {
            return (
              <Accordion key={group.id} title={group.name}>
                {group.concepts.map((concept) => (
                  <CheckboxItem
                    style={{
                      padding: "9px 17px",
                    }}
                    selected={selectedConcepts.includes(concept.id)}
                    onClick={() => {
                      if (selectedConcepts.includes(concept.id)) {
                        setSelectedConcepts(
                          selectedConcepts.filter((x) => x !== concept.id)
                        );
                      } else {
                        setSelectedConcepts([...selectedConcepts, concept.id]);
                      }
                    }}
                    key={concept.id}
                  >
                    <Checkbox value={selectedConcepts.includes(concept.id)} />
                    <Text>{concept.name}</Text>
                  </CheckboxItem>
                ))}
              </Accordion>
            );
          })}

          <Text textStyle="headingSmall">Supplemental Concepts</Text>
          <SearchInput
            items={(supplementalConceptGroups.data?.data ?? []).flatMap(
              (group) =>
                group.concepts.map((concept) => ({
                  label: concept.name,
                  fields: {
                    id: concept.id,
                    name: concept.name,
                  },
                  value: concept.id,
                }))
            )}
            selectedIds={selectedSupplementalConcepts}
            indexFields={["name"]}
            identityField="id"
            onSelect={(value) =>
              setSelectedSupplementalConcepts([
                ...selectedSupplementalConcepts,
                value,
              ])
            }
          />
          <Text>
            {selectedSupplementalConcepts
              .map((conceptId) => {
                const concept = supplementalConcepts?.find(
                  (concept) => concept.id === conceptId
                );
                return concept?.name;
              })
              .join(", ")}
          </Text>
          {supplementalConceptGroups.data?.data.map((group) => {
            return (
              <Accordion key={group.id} title={group.name}>
                {group.concepts.map((concept) => (
                  <CheckboxItem
                    style={{
                      padding: "9px 17px",
                    }}
                    selected={selectedSupplementalConcepts.includes(concept.id)}
                    onClick={() => {
                      if (selectedSupplementalConcepts.includes(concept.id)) {
                        setSelectedSupplementalConcepts(
                          selectedSupplementalConcepts.filter(
                            (x) => x !== concept.id
                          )
                        );
                      } else {
                        setSelectedSupplementalConcepts([
                          ...selectedSupplementalConcepts,
                          concept.id,
                        ]);
                      }
                    }}
                    key={concept.id}
                  >
                    <Checkbox
                      value={selectedSupplementalConcepts.includes(concept.id)}
                    />
                    <Text>{concept.name}</Text>
                  </CheckboxItem>
                ))}
              </Accordion>
            );
          })}

          <Text textStyle="headingSmall">Answers</Text>
          <Text>
            {answers.data?.data
              .map((answer) => {
                return answer.text;
              })
              .join(", ")}
          </Text>

          <Button
            type="button"
            style={{
              alignSelf: "flex-start",
            }}
            variant="primary"
            text="Delete question"
            onPress={() => {
              if (
                window.confirm(
                  "Are you sure you want to delete this question? This cannot be undone."
                )
              ) {
                deleteQuestion.mutate({
                  id: params.questionId,
                });
              }
            }}
            loading={deleteQuestion.isLoading}
          />
        </>
      )}
    </PageChrome>
  );
};

export default QuestionDetail;

// Typing underscores is important in the editor and the bold and italic input
// tules make it frustrating since they sometimes result in underscores being
// used for the formatting and don't always escape them
class CustomBoldExtension extends BoldExtension {
  createInputRules() {
    // Return an empty array or customized rules
    return [];
  }
}

class CustomItalicExtension extends ItalicExtension {
  createInputRules() {
    // Return an empty array or customized rules
    return [];
  }
}

const MarkdownInput = ({
  value,
  setValue,
}: {
  value: string;
  setValue: (_: string) => unknown;
}) => {
  const { manager, state, setState } = useRemirror({
    extensions: () => [
      new CustomBoldExtension(),
      new CustomItalicExtension(),
      new UnderlineExtension(),
    ],
    content: value,
    stringHandler: "html",
  });
  const designTokens = useDesignTokens();

  return (
    <Remirror
      state={state}
      onChange={(parameter) => {
        setState(parameter.state);
      }}
      manager={manager}
      classNames={["markdown-editor"]}
    >
      <span
        style={{
          backgroundColor: designTokens.colors.transparent,
          appearance: "none",
          gap: "8px",
          display: "flex",
          flex: 1,
          alignSelf: "stretch",
          borderRadius: 12,
          border: "1px solid rgba(20, 20, 20, 0.40)",
          ...designTokens.typography.body,
          lineHeight: "115%",
          position: "relative",
          flexBasis: 56,
        }}
      >
        <EditorComponent />
        <MarkdownPreview setMarkdown={setValue} />
      </span>
    </Remirror>
  );
};
