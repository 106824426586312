import { Text, Button, Input, useDesignTokens, Card } from "@gradience/ui";
import { useLogin } from "../../lib/auth";
import { useState } from "react";
import AuthChrome from "../../components/auth-chrome";
import { isApiError } from "../../lib/api";

function LogIn() {
  const designTokens = useDesignTokens();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const logInMutation = useLogin();

  return (
    <AuthChrome>
      <Card>
        <form
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 32,
          }}
          onSubmit={(e) => {
            e.preventDefault();
            logInMutation.mutate({ body: { username: email, password } });
          }}
        >
          <Text textStyle="headingLarge">Log In</Text>
          <Input
            label="Email"
            name="email"
            required
            value={email}
            setValue={setEmail}
          />
          <Input
            label="Password"
            name="password"
            type="password"
            required
            value={password}
            setValue={setPassword}
            helpLinkText="Forgot Password?"
            helpLink={{
              to: "/request-password-reset",
            }}
          />
          {logInMutation.error ? (
            <Text
              textStyle="strong"
              style={{
                color: designTokens.colors.text.error,
              }}
            >
              {isApiError(logInMutation.error)
                ? logInMutation.error?.response?.data.errors[0].message
                : logInMutation.error instanceof Error
                ? logInMutation.error.message
                : "Something went wrong"}
            </Text>
          ) : null}
          <div
            style={{
              display: "flex",
              gap: 32,
              flexDirection: "row",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <Button
              variant="primary"
              text="Log In"
              size="large"
              type="submit"
              loading={logInMutation.isLoading}
            />
          </div>
        </form>
      </Card>
    </AuthChrome>
  );
}

export default LogIn;
