import { useMutation } from "@tanstack/react-query";
import useApi, { getToken } from "./api";

const getGradeReport = async ({
  api,
}: {
  api: ReturnType<typeof useApi>;
}): Promise<File> => {
  if (!api) {
    throw new Error("API is initializing.");
  }

  const response = await api.get<File>(`/grade-report`, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${getToken()}`,
    },
    responseType: "blob",
  });

  return response.data;
};

export const useGetGradeReport = () => {
  const api = useApi();

  return useMutation(() => getGradeReport({ api }));
};
