import { Outlet, useMatches, useNavigate } from "@tanstack/react-router";
import { useEffect, useMemo } from "react";
import { useLoggedInUser } from "../lib/auth";

const unauthenticatedRoutes = [
  "/reset-password",
  "/request-password-reset",
  "/sign-up",
  "/log-in",
  "/",
];

function Root() {
  const navigate = useNavigate();
  const routes = useMatches();

  const user = useLoggedInUser();

  const path = useMemo(() => {
    return routes[1]?.pathname;
  }, [routes]);

  useEffect(() => {
    if (user.status !== "success") {
      return;
    }

    if (user.data) {
      if (unauthenticatedRoutes.includes(path)) {
        navigate({
          to: `/schools`,
        });
      }
    } else {
      if (!unauthenticatedRoutes.includes(path)) {
        navigate({
          to: "/log-in",
        });
      }
    }
  }, [user.data, path, navigate, user.isFetched, user.status]);

  return <Outlet />;
}

export default Root;
