import { useNavigate, useParams } from "@tanstack/react-router";
import { Button, Input, Loader, Row, Text, Tooltip } from "@gradience/ui";
import PageChrome from "../../components/page-chrome";
import { useApiDelete, useApiPut, useApiQuery } from "../../lib/api";
import { ReactNode, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";

const ConceptGroupDetail = (): ReactNode => {
  const params = useParams({ from: "/concepts/groups/$groupId" });

  const conceptGroups = useApiQuery("/concept-groups", {});
  const conceptGroup = conceptGroups.data?.data.find(
    (group) => group.id === params.groupId
  );

  const concepts = useApiQuery(
    "/concepts",
    {},
    {
      enabled: conceptGroups.isSuccess,
    }
  );

  const queryClient = useQueryClient();
  const updateConceptGroup = useApiPut(
    "/concept-groups/:id",
    {
      id: params.groupId,
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["concept-groups"]);
      },
    }
  );
  const navigate = useNavigate();
  const deleteConceptGroup = useApiDelete(
    "/concept-groups/:id",
    {
      id: params.groupId,
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["concept-groups"]);
        navigate({
          to: "/concepts",
        });
      },
    }
  );

  const [_conceptGroupName, setConceptGroupName] = useState<string | undefined>(
    undefined
  );
  const conceptGroupName = _conceptGroupName ?? conceptGroup?.name;

  const loading = updateConceptGroup.isLoading;
  const hasConcepts = conceptGroup?.concepts.length ? true : false;

  return (
    <PageChrome>
      {concepts.isInitialLoading ||
      conceptGroups.isInitialLoading ||
      conceptGroups.isInitialLoading ? (
        <Loader />
      ) : (
        <>
          <Row gap={8}>
            <Text
              textStyle="headingLarge"
              style={{
                flex: 1,
              }}
            >
              Concept Group
            </Text>
            <Button
              type="button"
              text="Cancel"
              linkProps={{
                to: "/concepts",
              }}
              disabled={loading}
            />
            <Button
              type="button"
              variant="primary"
              text="Save"
              onPress={() => {
                updateConceptGroup.mutate({
                  body: {
                    name: conceptGroupName ?? "",
                    conceptIds:
                      conceptGroup?.concepts.map((concept) => concept.id) ?? [],
                  },
                });
              }}
              loading={loading}
            />
          </Row>
          <Input value={conceptGroupName} setValue={setConceptGroupName} />
          <Tooltip
            content={
              hasConcepts
                ? "Delete each concept or move it to a different group before deleting the concept group"
                : undefined
            }
            style={{
              alignSelf: "flex-start",
            }}
          >
            <Button
              disabled={hasConcepts}
              type="button"
              style={{
                alignSelf: "flex-start",
              }}
              variant="primary"
              text="Delete group"
              onPress={() => {
                if (
                  window.confirm(
                    "Are you sure you want to delete this group? This cannot be undone."
                  )
                ) {
                  deleteConceptGroup.mutate({
                    id: params.groupId,
                  });
                }
              }}
              loading={deleteConceptGroup.isLoading}
            />
          </Tooltip>
        </>
      )}
    </PageChrome>
  );
};

export default ConceptGroupDetail;
