import { useDesignTokens } from "@gradience/ui";
import {
  EditorComponent,
  Remirror,
  useHelpers,
  useRemirror,
} from "@remirror/react";
import { useEffect } from "react";
import {
  BoldExtension,
  ItalicExtension,
  UnderlineExtension,
} from "remirror/extensions";

function StringifiedHtmlPreview({
  setMarkdown,
}: {
  setMarkdown: (_: string) => unknown;
}) {
  const { getHTML } = useHelpers(true);

  const markdown = getHTML();

  useEffect(() => {
    setMarkdown(markdown);
  }, [markdown, setMarkdown]);

  return null;
}

// Typing underscores is important in the editor and the bold and italic input
// tules make it frustrating since they sometimes result in underscores being
// used for the formatting and don't always escape them
class CustomBoldExtension extends BoldExtension {
  createInputRules() {
    // Return an empty array or customized rules
    return [];
  }
}

class CustomItalicExtension extends ItalicExtension {
  createInputRules() {
    // Return an empty array or customized rules
    return [];
  }
}

const MarkdownInput = ({
  value,
  setValue,
}: {
  value: string;
  setValue: (_: string) => unknown;
}) => {
  const { manager, state, setState } = useRemirror({
    extensions: () => [
      new CustomBoldExtension(),
      new CustomItalicExtension(),
      new UnderlineExtension(),
    ],
    content: value,
    stringHandler: "html",
  });
  const designTokens = useDesignTokens();

  return (
    <Remirror
      state={state}
      onChange={(parameter) => {
        setState(parameter.state);
      }}
      manager={manager}
      classNames={["markdown-editor"]}
    >
      <span
        style={{
          backgroundColor: designTokens.colors.transparent,
          appearance: "none",
          gap: "8px",
          display: "flex",
          flexShrink: 0,
          borderRadius: 12,
          border: "1px solid rgba(20, 20, 20, 0.40)",
          ...designTokens.typography.body,
          lineHeight: "115%",
          position: "relative",
          flexBasis: 56,
          minHeight: 56,
        }}
      >
        <EditorComponent />
        <StringifiedHtmlPreview setMarkdown={setValue} />
      </span>
    </Remirror>
  );
};

export default MarkdownInput;
