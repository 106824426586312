import {
  Text,
  Loader,
  useDesignTokens,
  Row,
  MarkdownRenderer,
  Button,
} from "@gradience/ui";
import PageChrome from "../../components/page-chrome";
import { useApiPost, useApiQuery } from "../../lib/api";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Question } from "@gradience/api-types";
import { useEffect, useMemo } from "react";
import { Link, useParams } from "@tanstack/react-router";

const columnHelper = createColumnHelper<Question>();

function Questions() {
  const { poolId } = useParams({
    from: "/questions/$poolId",
  });
  const questionPools = useApiQuery(`/question-pools`, {});
  const questionPool = questionPools.data?.data.find(
    (questionPool) => questionPool.id === poolId
  );
  const questions = useApiQuery(
    "/questions",
    {},
    {
      enabled: questionPools.isSuccess,
    },
    {
      ids: questionPool?.questionIds ?? [],
    }
  );

  // Refetch questions when the question pool changes
  useEffect(() => {
    questions.refetch();
  }, [questionPool?.questionIds, questions]);

  const concepts = useApiQuery(
    "/concepts",
    {},
    {
      enabled: questions.isSuccess,
    },
    {
      ids:
        questions.data?.data.flatMap((question) => question.conceptIds) ?? [],
    }
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor("text", {
        header: "Question",
        cell: (row) => (
          <Text>
            <Link
              to="/questions/$poolId/$questionId"
              params={{
                poolId,
                questionId: row.row.original.id,
              }}
              style={{
                color: "inherit",
                textDecoration: "none",
              }}
            >
              <MarkdownRenderer content={row.getValue()} />
            </Link>
          </Text>
        ),
      }),
      columnHelper.display({
        id: "concepts",
        header: "Concepts",
        cell: (row) => (
          <Text>
            {row.row.original.conceptIds
              .map((conceptId) => {
                const concept = concepts.data?.data.find(
                  (concept) => concept.id === conceptId
                );
                return concept?.slug ?? concept?.name;
              })
              .join(", ")}
          </Text>
        ),
      }),
    ],
    [concepts.data?.data, poolId]
  );

  const { getHeaderGroups, getRowModel, reset } = useReactTable({
    columns,
    // If there are no questions, axios doesn't include the query param so we
    // get all questions, so show none in that case
    data: questionPool?.questionIds.length ? questions.data?.data ?? [] : [],
    getCoreRowModel: getCoreRowModel(),
  });

  const createQuestionMutation = useApiPost("/questions", {
    onSuccess: async () => {
      await questionPools.refetch();
      await questions.refetch();
      reset();
    },
  });

  const designTokens = useDesignTokens();
  const QuestionsTable = () => (
    <table
      style={{
        width: "100%",
        borderCollapse: "collapse",
      }}
    >
      <thead>
        {getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th
                key={header.id}
                style={{
                  paddingTop: 14,
                  paddingBottom: 14,
                  paddingLeft: 24,
                  paddingRight: 24,
                  borderTop: `1px solid ${designTokens.colors.border.subdued}`,
                  borderBottom: `1px solid ${designTokens.colors.border.subdued}`,
                  textAlign: "start",
                }}
              >
                <Text textStyle="strong">
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </Text>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {getRowModel().rows?.map((row) => (
          <tr key={row.id}>
            {row.getVisibleCells()?.map((cell) => (
              <td
                key={cell.id}
                style={{
                  paddingTop: 14,
                  paddingBottom: 14,
                  paddingLeft: 24,
                  paddingRight: 24,
                  borderTop: `1px solid ${designTokens.colors.border.subdued}`,
                }}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );

  return (
    <PageChrome>
      <Row
        style={{
          paddingTop: 11,
          paddingBottom: 11,
          gap: 8,
        }}
      >
        <Text
          textStyle="headingLarge"
          style={{
            flexGrow: 1,
          }}
        >
          Questions
        </Text>
        <Button
          type="button"
          text="Back"
          linkProps={{
            to: "/questions",
          }}
          disabled={createQuestionMutation.isLoading}
        />
      </Row>
      {questions.isLoading || concepts.isLoading ? (
        <Loader />
      ) : (
        <>
          <span
            style={{
              flex: 1,
            }}
          >
            <QuestionsTable />
          </span>
          <Button
            style={{
              alignSelf: "flex-start",
            }}
            text="Add question"
            onPress={() => {
              createQuestionMutation.mutate({
                body: {
                  text: "New question",
                  questionPoolId: poolId,
                  answers: [],
                },
              });
            }}
          />
        </>
      )}
    </PageChrome>
  );
}

export default Questions;
