import { DropdownMenu, Input } from "@gradience/ui";
import { useMemo, useState } from "react";
import lunr from "lunr";

function isNotNull<T>(value: T | null): value is T {
  return value !== null;
}

/**
 * A search box that lets you search through a list of items and select one.
 * Each item includes a label which will be displayed, a value which will be
 * returned when the item is selected, and a list of fields which will be
 * indexed for searching. The fields should also contain an identity field which
 * uniquely identifies the item. Optionally, you can pass a list of already
 * selected items, which will be excluded from the search results.
 */
const SearchInput = <
  TItem extends {
    label: string;
    fields: Record<string, string>;
    value: unknown;
  },
  TIndexField extends TItem["fields"][string],
  TIdentityField extends TItem["fields"][string]
>({
  onSelect,
  items,
  indexFields,
  identityField,
  placeholder = "Search",
  selectedIds = [],
}: {
  onSelect: (selectedItem: TItem["value"]) => unknown;
  items: TItem[];
  indexFields: TIndexField[];
  identityField: TIdentityField;
  selectedIds?: string[];
  placeholder?: string;
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [spanRef, setSpanRef] = useState<HTMLSpanElement | null>(null);
  const [searchResults, setSearchResults] = useState<string[]>([]);

  const index = useMemo(() => {
    if (items.length) {
      return lunr(function () {
        this.ref(identityField.toString());
        for (const field of indexFields) {
          this.field(field.toString());
        }

        for (const item of items) {
          if (!selectedIds.includes(item.fields[identityField])) {
            this.add(item.fields);
          }
        }
      });
    } else {
      return null;
    }
  }, [identityField, indexFields, items, selectedIds]);

  return (
    <span
      style={{
        position: "relative",
      }}
      ref={setSpanRef}
    >
      <Input
        value={searchQuery}
        placeholder={placeholder}
        setValue={(value) => {
          setSearchQuery(value);
          // Lunr throws an error if the query is e.g. "-"
          let results: lunr.Index.Result[];
          try {
            results = index?.search(value) ?? [];
          } catch (e) {
            return;
          }
          setSearchResults(
            results
              .sort((a, b) => b.score - a.score)
              .slice(0, 5)
              .map((result) => result.ref)
          );
        }}
        inputProps={{
          autoComplete: "off",
          onFocus: (event) => {
            let results: lunr.Index.Result[];
            try {
              results = index?.search(event.target.value) ?? [];
            } catch (e) {
              return;
            }
            setSearchResults(
              results
                .sort((a, b) => b.score - a.score)
                .slice(0, 5)
                .map((result) => result.ref)
            );
          },
          onBlur: () => {
            setSearchResults([]);
          },
        }}
      />
      {searchResults.length ? (
        <DropdownMenu
          setClosed={() => setSearchResults([])}
          options={searchResults
            .map((result) => {
              const item = items.find(
                (item) => item.fields[identityField] === result
              );

              if (!item) {
                return null;
              }

              return {
                label: item.label,
                value: item.fields[identityField],
              };
            })
            .filter(isNotNull)}
          referenceElement={spanRef}
          onSelect={(value) => {
            onSelect(value);
            setSearchResults([]);
            setSearchQuery("");
          }}
        />
      ) : null}
    </span>
  );
};

export default SearchInput;
