export function clamp(value: number, min?: number, max?: number) {
  return typeof max === "number" ? Math.min(value, max) : value;
}

/*
 * Converts a base 10 number to a two-digit hexadecimal (clamped from 0 to 255)
 */
const toHex = (num: number): string =>
  clamp(Math.round(num), 0, 255).toString(16).padStart(2, "0");

/*
 * Converts a hexadecimal number to a base 10 number
 */
const fromHex = (num: string): number => parseInt(num, 16);

/*
 * Converts a numeric alpha percentage (0-1) to its hex equivalent (00-ff)
 */
const alphaToHex = (alpha: number): string => toHex(alpha * 255);

/**
 * Adds alpha to a 6-digit hex color. Alpha is specified as a numeric percentage
 * from 0-1. This function does not work with shorthand hex colors.
 */
export const colorWithAlpha = (color: string, alpha: number): string =>
  color + alphaToHex(alpha);

/**
 * Mixes two numbers according to the amount (if amount is 0 then color a is
 * returned, if amount is 1 then color b is returned, anything in-between
 * results in a mix of the two colors that varies by the amount).
 * See: https://stackoverflow.com/a/56348573/2747759
 */
export const mixColors = (
  a: string,
  b: string,
  amount: number = 0.5
): string => {
  const [rA = 0, gA = 0, bA = 0] = (a.match(/[0-9a-f]{2}/gi) || []).map(
    fromHex
  );
  const [rB = 0, gB = 0, bB = 0] = (b.match(/[0-9a-f]{2}/gi) || []).map(
    fromHex
  );
  const rMix = toHex(rA + (rB - rA) * amount);
  const gMix = toHex(gA + (gB - gA) * amount);
  const bMix = toHex(bA + (bB - bA) * amount);
  return "#" + rMix + gMix + bMix;
};
