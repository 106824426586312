import {
  Text,
  useDesignTokens,
  Row,
  Column,
  Loader,
  Button,
  Card,
  Input,
} from "@gradience/ui";
import Konva from "konva";
import { Stage, Layer, Transformer, Rect } from "react-konva";
import PageChrome from "../../components/page-chrome";
import { useApiDelete, useApiPost, useApiQuery } from "../../lib/api";
import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { useHover } from "@uidotdev/usehooks";
import { AnswerCircleAnnotation } from "@gradience/api-types/src/answer-circle-annotation";
import { useNavigate, useSearch } from "@tanstack/react-router";
import { manualGradingIssuesRoute } from "../..";
import { Student, TestScanPage } from "@gradience/api-types";
import { colorWithAlpha } from "../../lib/colors";
import styled from "styled-components";
import { useQueryClient } from "@tanstack/react-query";

const displayIssueType = (issueType: string) => {
  switch (issueType) {
    case "INCORRECT_IDENTIFICATION":
      return "The system incorrectly identified the student’s answer";
    case "FALSE_POSITIVE":
      return "The system identified an answer where none was given";
    case "FALSE_NEGATIVE":
      return "The system failed to identify an answer where one was given";
    case "OTHER":
      return "Other";
    default:
      return issueType;
  }
};

function ManualGradingIssues() {
  const { gradingPage } = useSearch({ from: manualGradingIssuesRoute.id });
  const issues = useApiQuery(
    "/grading-issues",
    {},
    { refetchOnWindowFocus: false },
    { take: "1", skip: gradingPage.toString() }
  );

  const navigate = useNavigate();
  const setGradingPage = (page: number) => {
    navigate({
      to: manualGradingIssuesRoute.id,
      search: {
        gradingPage: page,
      },
    });
  };

  const students = useApiQuery(
    "/students",
    {},
    {
      enabled: Boolean(issues.data?.data[0]?.studentId),
    },
    { studentId: issues.data?.data[0]?.studentId }
  );
  const student = students.data?.data[0];

  const testScanPageQuery = useApiQuery(
    "/test-scan-pages",
    {},
    {
      enabled: student !== undefined,
      refetchOnWindowFocus: false,
    },
    {
      studentId: student?.id,
      take: "20",
    }
  );

  const issue = issues.data?.data[0];
  const designTokens = useDesignTokens();

  const queryClient = useQueryClient();

  const answerCircleAnnotationMutation = useApiPost(
    "/test-scan-pages/:id/partial-answer-circle-annotations",
    {}
  );
  const resolveIssueMutation = useApiPost("/grading-issues/:id/resolution");
  const unresolveIssueMutation = useApiDelete(
    "/grading-issues/:id/resolution",
    { id: issue?.id ?? "" }
  );

  const disableNavigation =
    resolveIssueMutation.isLoading ||
    unresolveIssueMutation.isLoading ||
    answerCircleAnnotationMutation.isLoading;

  return (
    <PageChrome>
      {issue ? (
        <Row gap={16}>
          <Column
            style={{
              flexGrow: 1,
              alignItems: "center",
            }}
          >
            {testScanPageQuery.isLoading || !student ? (
              <Loader />
            ) : testScanPageQuery.data?.data.length ? (
              testScanPageQuery.data?.data.map((page) => (
                <TestScanPageViewer
                  page={page}
                  student={student}
                  key={page.id}
                  setCircles={(annotations) => {
                    // update query cache
                    queryClient.setQueryData(
                      [
                        "test-scan-pages",
                        {},
                        {
                          studentId: student?.id,
                          take: "20",
                        },
                      ],
                      {
                        data: testScanPageQuery.data?.data.map((p) => {
                          if (p.id === page.id) {
                            return {
                              ...p,
                              answerCircleLocations:
                                p.answerCircleLocations?.map((circle) => {
                                  const annotation = annotations.find(
                                    (a) => a.x === circle.x && a.y === circle.y
                                  );
                                  return {
                                    ...circle,
                                    filledIn:
                                      annotation?.filled ?? circle.filledIn,
                                  };
                                }),
                            };
                          }
                          return p;
                        }),
                      }
                    );

                    answerCircleAnnotationMutation.mutate({
                      body: { annotations },
                      params: { id: page.id },
                    });
                  }}
                />
              ))
            ) : (
              <div
                style={{
                  backgroundColor: designTokens.colors.surface.subdued,
                  borderRadius: 12,
                  padding: 24,
                }}
              >
                <Text>
                  No test was graded for this student. Either it wasn't
                  received, or the image wasn't readable.
                </Text>
              </div>
            )}
          </Column>
          <Column
            style={{
              flexBasis: 300,
              flexGrow: 0,
              flexShrink: 0,
              gap: 16,
            }}
          >
            <Column
              style={{
                position: "sticky",
                top: 32,
                display: "flex",
                flexDirection: "column",
                height: "calc(100dvh - 32px)",
                paddingBottom: 8,
                gap: 16,
                boxSizing: "border-box",
              }}
            >
              <Card
                style={{
                  padding: 24,
                  gap: 4,
                }}
              >
                <Text textStyle="headingXS">
                  {student?.firstName} {student?.lastName}
                </Text>
                <Text>
                  Question:{" "}
                  {issue.pageNumber === undefined
                    ? "Whole page"
                    : issue.pageNumber + 1}
                </Text>
                <Text>{displayIssueType(issue.issueType)}</Text>
                {issue.comment ? (
                  <Text>
                    <i>"{issue.comment}"</i>
                  </Text>
                ) : null}
              </Card>
              <Card
                style={{
                  padding: 24,
                  gap: 4,
                }}
              >
                <Text textStyle="headingSmall" style={{ flexGrow: 1 }}>
                  Grading {gradingPage + 1} / {issues.data?.count}
                </Text>
                <Row
                  gap={8}
                  style={{
                    alignItems: "center",
                    padding: 16,
                  }}
                >
                  <Button
                    onPress={() => setGradingPage(gradingPage - 1)}
                    disabled={gradingPage === 0 || disableNavigation}
                    text="Previous"
                  />
                  <Button
                    onPress={() => {
                      // if the issue is resolved, just refetch to continue
                      if (issue.resolved) {
                        queryClient.refetchQueries(["grading-issues"]);
                      } else {
                        setGradingPage(gradingPage + 1);
                      }
                    }}
                    disabled={
                      gradingPage === (issues.data?.count ?? 0) - 1 ||
                      disableNavigation
                    }
                    text="Next"
                  />
                </Row>
              </Card>
              <Card
                style={{
                  padding: 24,
                  gap: 4,
                }}
              >
                <Row gap={8}>
                  <Text>
                    {answerCircleAnnotationMutation.isLoading
                      ? "Saving..."
                      : "All changes saved"}
                  </Text>
                </Row>
                <Button
                  disabled={disableNavigation}
                  onPress={() => {
                    const updateCache = (resolved: boolean) =>
                      queryClient.setQueryData(
                        [
                          "grading-issues",
                          {},
                          { take: "1", skip: gradingPage.toString() },
                        ],
                        {
                          data: issues.data?.data.map((i) => {
                            if (i.id === issue.id) {
                              return {
                                ...i,
                                resolved,
                              };
                            }
                            return i;
                          }),
                          count: issues.data?.count,
                        }
                      );

                    if (issue.resolved) {
                      unresolveIssueMutation.mutate(
                        { id: issue.id },
                        {
                          onSuccess: () => {
                            updateCache(false);
                          },
                        }
                      );
                    } else {
                      resolveIssueMutation.mutate(
                        {
                          params: { id: issue.id },
                          body: {},
                        },
                        {
                          onSuccess: () => {
                            updateCache(true);
                          },
                        }
                      );
                    }
                  }}
                  text={issue.resolved ? "Reopen" : "Resolve"}
                />
              </Card>
            </Column>
          </Column>
        </Row>
      ) : (
        <Loader />
      )}
    </PageChrome>
  );
}

const ResizableBox = ({
  boxPosition,
  setBoxPosition,
}: {
  boxPosition: {
    x: number;
    y: number;
    width: number;
    height: number;
    rotation: number;
  };
  setBoxPosition: (position: {
    x: number;
    y: number;
    width: number;
    height: number;
    rotation: number;
  }) => void;
}) => {
  const shapeRef = useRef<Konva.Rect>(null);
  const transformerRef = useRef<Konva.Transformer>(null);
  const layerRef = useRef<Konva.Layer>(null);

  useEffect(() => {
    if (transformerRef.current && shapeRef.current) {
      transformerRef.current.nodes([shapeRef.current]);
      transformerRef.current.getLayer()?.batchDraw();
    }
  }, []);

  return (
    <>
      <Layer ref={layerRef}>
        <Rect
          onTransformEnd={(event) => {
            setBoxPosition({
              x: event.target.x(),
              y: event.target.y(),
              width: event.target.scaleX(),
              height: event.target.scaleY(),
              rotation: event.target.rotation(),
            });
          }}
          onDragEnd={(event) => {
            setBoxPosition({
              x: event.target.x(),
              y: event.target.y(),
              width: event.target.scaleX(),
              height: event.target.scaleY(),
              rotation: event.target.rotation(),
            });
          }}
          x={boxPosition.x}
          y={boxPosition.y}
          width={1}
          height={1}
          scaleX={boxPosition.width}
          scaleY={boxPosition.height}
          draggable
          ref={shapeRef}
        />
        <Transformer
          ref={transformerRef}
          ignoreStroke
          keepRatio
          enabledAnchors={[
            "top-left",
            "top-right",
            "bottom-left",
            "bottom-right",
          ]}
        />
      </Layer>
    </>
  );
};

export default ManualGradingIssues;

const BRACKET_1_DISTANCE_FROM_TOP = 619;
const BRACKET_1_DISTANCE_FROM_LEFT = 810;
const BRACKET_3_DISTANCE_FROM_LEFT = 5250;
const PAGE_WIDTH = 6120;
const PAGE_HEIGHT = 7920;

const TestScanPageViewer = ({
  page,
  student,
  setCircles,
}: {
  page: TestScanPage;
  student: Student;
  setCircles: (
    annotations: (AnswerCircleAnnotation & {
      answerId: string;
      questionId: string;
    })[]
  ) => unknown;
}) => {
  const designTokens = useDesignTokens();
  const [imgDimensions, setImgDimensions] = useState({
    width: 0,
    height: 0,
  });

  const transformScan = useMemo(() => {
    const fiducials = page.fiducialLocations;

    if (!fiducials || !imgDimensions) {
      return null;
    }

    const topLeftFiducial = fiducials.find(
      (fiducial) => fiducial.type === "TOP_LEFT"
    );

    const topRightFiducial = fiducials.find(
      (fiducial) => fiducial.type === "TOP_RIGHT"
    );

    if (!topLeftFiducial || !topRightFiducial) {
      return {};
    }

    const standardDistanceBetweenFiducials =
      BRACKET_3_DISTANCE_FROM_LEFT - BRACKET_1_DISTANCE_FROM_LEFT;
    const scanDistanceBetweenFiducials = Math.sqrt(
      Math.pow(topRightFiducial.x - topLeftFiducial.x, 2) +
        Math.pow(topRightFiducial.y - topLeftFiducial.y, 2)
    );
    const scanScale =
      standardDistanceBetweenFiducials / scanDistanceBetweenFiducials;

    const scaleTransformX = -(
      BRACKET_1_DISTANCE_FROM_LEFT / scanScale -
      topLeftFiducial.x
    );
    const scaleTransformY = -(
      BRACKET_1_DISTANCE_FROM_TOP / scanScale -
      topLeftFiducial.y
    );

    const scanRotation = Math.atan2(
      topRightFiducial.y - topLeftFiducial.y,
      topRightFiducial.x - topLeftFiducial.x
    );

    const percentOfSpaceBetweenFiducials =
      standardDistanceBetweenFiducials / PAGE_WIDTH;
    const scanPercentOfSpaceBetweenFiducials =
      scanDistanceBetweenFiducials / imgDimensions.width;

    return {
      transformOrigin: `0px 0px`,
      transform: `rotate(${scanRotation}rad) translate(${
        (scaleTransformX / imgDimensions.width) * 100
      }%, ${(scaleTransformY / imgDimensions.height) * 100}%) scale(${
        scanPercentOfSpaceBetweenFiducials / percentOfSpaceBetweenFiducials
      })`,
    };
  }, [page, imgDimensions]);

  const pageNumberInTest = page.pageNumberInTest;
  if (pageNumberInTest === undefined) {
    return null;
  }
  return (
    <div
      style={{
        width: "100%",
        position: "relative",
        border: `2px solid ${designTokens.colors.border.subdued}`,
        borderRadius: 12,
        overflow: "hidden",
      }}
    >
      <img
        style={{
          width: "100%",
        }}
        src={page.imageUrl}
        alt={`Test page ${pageNumberInTest} for ${student.firstName} ${student.lastName}`}
        onLoad={(event) => {
          setImgDimensions({
            width: event.currentTarget.naturalWidth,
            height: event.currentTarget.naturalHeight,
          });
        }}
      />
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: `${
            ((imgDimensions.height -
              imgDimensions.width * (PAGE_HEIGHT / PAGE_WIDTH)) /
              imgDimensions.height) *
            100
          }%`,
          ...transformScan,
        }}
      >
        {page.answerCircleLocations
          ?.filter((circle) => circle.page === (pageNumberInTest ?? 0) - 1)
          .map((circle, index) => {
            return (
              <AnswerCircle
                key={index}
                x={circle.x}
                y={circle.y}
                width={circle.width}
                height={circle.height}
                filledIn={circle.filledIn}
                onClick={() => {
                  const annotations: (AnswerCircleAnnotation & {
                    answerId: string;
                    questionId: string;
                  })[] = [];
                  const thisQuestionsCircles =
                    page.answerCircleLocations?.filter(
                      (c) => c.questionId === circle.questionId
                    );

                  for (const _circle of thisQuestionsCircles ?? []) {
                    annotations.push({
                      x: _circle.x,
                      y: _circle.y,
                      width: _circle.width,
                      height: _circle.height,
                      filled:
                        circle.answerId === _circle.answerId
                          ? // toggle filled in
                            !circle.filledIn
                          : // anything not clicked is unfilled
                            false,
                      answerId: _circle.answerId,
                      questionId: _circle.questionId,
                    });
                  }

                  setCircles(annotations);
                }}
              />
            );
          })}
      </div>
    </div>
  );
};

const AnswerCircle = styled.div<{
  x: number;
  y: number;
  width: number;
  height: number;
  filledIn: boolean;
}>`
  position: absolute;
  border: 2px solid ${(props) => props.theme.colors.brand.critical};
  border-radius: 50%;
  background-color: ${(props) =>
    props.filledIn
      ? colorWithAlpha(props.theme.colors.brand.critical, 0.5)
      : "transparent"};
  top: ${(props) => `${((props.y - 4) / 792) * 100}%`};
  left: ${(props) => `${((props.x - 4) / 612) * 100}%`};
  width: ${(props) => `${((props.width + 8) / 612) * 100}%`};
  height: ${(props) => `${((props.height + 8) / 792) * 100}%`};
  cursor: pointer;

  &:hover {
    background-color: ${(props) =>
      props.filledIn
        ? colorWithAlpha(props.theme.colors.brand.critical, 0.75)
        : colorWithAlpha(props.theme.colors.brand.critical, 0.25)};
  }
`;
