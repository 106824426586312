import { Link } from "@tanstack/react-router";
import PageChrome from "../../components/page-chrome";
import { useApiQuery } from "../../lib/api";

function ManualGrading() {
  const gradingIssues = useApiQuery("/grading-issues", {}, {}, { take: "0" });
  const failedScan = useApiQuery(
    "/test-scan-pages",
    {},
    {},
    {
      failedIdentifyingFiducialsOrQrCode: "true",
      take: "0",
      distinctByPageNumberAndStudent: "false",
    }
  );

  return (
    <PageChrome>
      <table
        style={{
          borderCollapse: "collapse",
          width: "100%",
        }}
      >
        <thead>
          <tr>
            <th>Name</th>
            <th>Number</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <Link
                to="/manual-grading/issues"
                search={{
                  gradingPage: 0,
                }}
              >
                Issues
              </Link>
            </td>
            <td>{gradingIssues.data?.count ?? "Loading..."}</td>
          </tr>
          <tr>
            <td>
              <Link
                to="/manual-grading/errors"
                search={{
                  gradingPage: 0,
                }}
              >
                Errors
              </Link>
            </td>
            <td>{failedScan.data?.count ?? "Loading..."}</td>
          </tr>
        </tbody>
      </table>
    </PageChrome>
  );
}

export default ManualGrading;
